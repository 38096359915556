import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  parse,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  format,
  isValid,
  parseISO,
  isDate,
  getDaysInMonth,
  endOfMonth,
  subYears,
  subWeeks,
  subDays,
  subMonths,
} from "date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Icon,
  InputBase,
  Card,
  CardContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import {
  RECURRENCE_PAYMENT,
  ROUTE,
  ACCOUNT_TYPEOF,
  DEFAULT_QUERY,
  ACCOUNT_STATUS,
  PERMISSION,
} from "../../../config/constants";
import * as AccountApi from "@api/backend/account";
import * as UtilsActions from "../../../store/ducks/utils/actions";
import * as AuxActions from "../../../store/ducks/aux/actions";
import * as Helper from "../../../utils/helper";

import useStyles from "../../../styles";
import {
  colorCancel,
  colorSecondary,
  colorWarning,
} from "../../../config/colors";
import { useDialogAccountDetail } from "./AccountDetail";
import { useLocation, useNavigate } from "react-router-dom";
import AccountAssociateTable from "./account/AccountAssociateTable";
import { ptBR } from "date-fns/locale";
import CancelAccount from "./CancelAccount";
import RemoveCancelAccount from "./RemoveCancelAccount";
import AccessControl from "../../../components/AccessControl";
import { isNumber } from "util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPartialReceive = (props) => {
  const { open, onClose, account, createAccount, closeDialog } = props;
  const [data, setData] = useState(account);

  const handleSubmit = useCallback(() => {
    onClose(data);
  }, [data]);

  const handleRecuse = useCallback(() => {
    createAccount(data);
    closeDialog();
  }, [data]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Foi recebido valor parcial ?</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
          <p>
            Identificamos que o pagamento foi menor que o valor total da conta,
            deseja gerar uma nova conta com o valor restante?
          </p>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={true}
                name="price"
                label="Valor Original"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                value={
                  data.total_history
                    ? Helper.numberToReal(data.total_history)
                    : 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={true}
                name="total"
                label="Valor recebido"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                value={data.total}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleRecuse} color="secondary">
          Recusar
        </Button>
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          variant="contained"
          color="primary"
        >
          Gerar conta adicional
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateAllAccounts = (props) => {
  const { account, showDialog, setShowDialog, newDate } = props;

  const classes = useStyles();

  const [accounts, setAccounts] = useState([]);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleConfirm = useCallback(async () => {
    dispatch(UtilsActions.setLoading());
    const result = await AccountApi.updateAccountsBulk({
      accounts: accounts.map((item) => ({
        id: item.accountId,
        due_date: item.new_due_date,
      })),
    });
    dispatch(UtilsActions.resetLoading());
    if (!result.error) {
      dispatch(
        UtilsActions.enqueueSnackbar("success", `Conta alteradas com sucesso!`)
      );
      setShowDialog(false);
    }
  }, [dispatch, accounts, setShowDialog]);

  useEffect(() => {
    async function fetchData() {
      //handleExpirationDateChange
      const accounts = await AccountApi.getAccountByBatchId(account.batch_id);
      var accountsFiltered = accounts.data.filter(
        (item) =>
          item.account_id !== account.account_id &&
          item.received === 0 &&
          new Date(item.due_date) > account.old_due_date
      );

      accountsFiltered = accountsFiltered.map((item, index = 1) => {
        const new_due_date = addMonths(new Date(newDate), 1 + index);

        return {
          ...item,
          new_due_date,
        };
      });

      setAccounts(accountsFiltered);
    }
    fetchData();
  }, [account]);

  return (
    showDialog && (
      <Dialog open={showDialog} onClose={handleClose}>
        <DialogTitle>Atualizar contas</DialogTitle>
        <DialogContent>
          <Typography>
            Ao confirmar as contas posteriores, ainda pendente, terão seus
            vencimentos alterados.
          </Typography>
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Conta</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell></TableCell>
                <TableCell>Novo vencimento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts &&
                accounts.length > 0 &&
                accounts.map((element) => (
                  <TableRow key={element.account_id}>
                    <TableCell>{element.description}</TableCell>
                    <TableCell>{Helper.formatDate(element.due_date)}</TableCell>
                    <TableCell>
                      <Icon
                        style={{ color: `green` }}
                        className={clsx("fas fa-arrow-right", classes.icon)}
                      />
                    </TableCell>
                    <TableCell>
                      {format(element.new_due_date, "dd/MM/yyyy", {
                        locale: ptBR,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            fullWidth
            color="secondary"
          >
            Não Atualizar
          </Button>
          <Button
            type="submit"
            onClick={() => handleConfirm()}
            variant="contained"
            color="primary"
            fullWidth
          >
            Atualizar contas
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

const AccountCreateReceive = (props) => {
  const {
    isDialogOpen: show,
    openDialog,
    closeDialog,
    accountId,
    defineAccountId,
    setForceUpate,
    dispatch,
    state,
  } = useDialogAccountDetail();

  const classes = useStyles();

  const auxClients = useSelector((state) => state.aux.clients);
  const auxAccountTypes = useSelector((state) => state.aux.accountTypes);
  const location = useLocation();
  const history = useNavigate();

  const NEW_QUERY = {
    search: "",
    page: 1,
    rowsPerPage: 200,
    orderColumn: "id",
    orderOrientation: "asc",
  };

  const FILTERS_QUERY = {
    ...DEFAULT_QUERY,
    rowsPerPage: -1,
    orderColumn: "id",
    orderOrientation: "asc",
  };

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(
    new Date()
  );
  const [paymentReceiveDate, setPaymentReceiveDate] = useState(new Date());
  const [recurrencePayment, setRecurrencePayment] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState(null);
  const [paidStatus, setPaidStatus] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [parcelOccurrence, setParcelOccurrence] = useState(1);
  const [allFamilyGrouped, setAllFamilyGrouped] = useState([]);
  const [negativeValue, setNegativeValue] = useState("black");
  const [frequencyState, setFrequencyState] = useState(0);
  const [showCancelAccount, setShowCancelAccount] = useState(false);
  const [showRemoveCancelAccount, setShowRemoveCancelAccount] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [accordionInfo, setAccordionInfo] = useState(true);
  const [accordionLaunch, setAccordionLaunch] = useState(true);
  const [accordionRecurence, setAccordionRecurence] = useState(false);
  const [accordionReceive, setAccordionReceive] = useState(false);
  const [accordionComment, setAccordionComment] = useState(false);
  const [showUpdateDate, setShowUpdateDate] = useState(false);
  const [parcelTotal, setParcelTotal] = useState(0);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const RECEIVE_ACCOUNT = {
    account_type_id: 0, //null,
    client_id: 0,
    bill: 0,
    description: "",
    competence: "",
    account_repeat: 1,
    due_date: new Date(),
    comments: "",
    price: "0,00",
    category_id: 0,
    nf_number: "",
    receive_date: null,
    closure_client_id: null,
    kind_of: ACCOUNT_TYPEOF.RECEIVE,
    discount: "0,00",
    tax: "0,00",
    total: "0,00",
    received: 0,
    receive_methods: 2,
  };

  const [showPartial, setShowPartial] = useState(false);
  const [account, setAccount] = useState(RECEIVE_ACCOUNT);
  const listClientsFormatted = useMemo(() => {
    const clientsFiltered = auxClients;
    return clientsFiltered;
  }, [auxClients]);

  const handleGetDomains = async () =>
    setPaymentMethods(await AccountApi.getDomains());

  const validateReceiveAccount = useCallback(() => {
    if (
      account.received &&
      Number(Helper.realToNumber(String(account.total))) < account.total_history
    ) {
      setShowPartial(true);
    } else {
      setShowPartial(false);
      handleSubmit(account).then((result) => {
        if (result) {
          closeDialog();
          setForceUpate(true);
        }
      });
    }
  }, [account]);

  const handleClose = useCallback(() => {
    setShowPartial(false);
    closeDialog();
  }, [closeDialog]);

  const savePartial = useCallback(
    (data) => {
      setShowPartial(false);

      handleSubmit(data).then((result) => {
        if (result) {
          const newPrice = Helper.formatNumberToReal(
            String(
              Number(data.total_history) -
                Number(Helper.realToNumber(data.total))
            ),
            "decimal"
          );

          const accountTemp = account;
          accountTemp.price = newPrice;
          accountTemp.total = newPrice;
          accountTemp.total_history = Helper.realToNumber(newPrice);
          accountTemp.receive_date = null;
          accountTemp.received = 0;
          accountTemp.tax = 0;
          accountTemp.discount = 0;
          accountTemp.account_repeat = 1;
          accountTemp.parcels = [];
          accountTemp.comments =
            "Conta gerada com base na conta de código " +
            accountId +
            ", cópia gerada em: " +
            format(new Date(), "dd/MM/yyyy");
          accountTemp.nf_number = "";
          accountTemp.partial = true;

          setPaidStatus(false);
          defineAccountId(null);
          setAccount(accountTemp);

          const newUrl = location.pathname.split("#")[0];

          history(newUrl + "#recebimento=");
        }
      });
    },
    [closeDialog]
  );

  const handleSubmit = useCallback(
    async (account) => {
      if (account) {
        if (account.client_id === 0) {
          dispatch(
            UtilsActions.enqueueSnackbar("warning", "Selecione um Cliente!")
          );
          return false;
        }
        if (account.category_id === 0 || account.category_id === null) {
          dispatch(
            UtilsActions.enqueueSnackbar(
              "warning",
              "Selecione um Plano de Contas!"
            )
          );
          return false;
        }
        if (
          (account.account_repeat === null || account.account_repeat === 0) &&
          recurrencePayment
        ) {
          dispatch(
            UtilsActions.enqueueSnackbar(
              "warning",
              "Número de Parcelas Inválido!"
            )
          );
          return false;
        }

        let accountData = {
          ...account,
          price: Number(Helper.realToNumber(String(account.price))),
          tax: Number(Helper.realToNumber(String(account.tax))),
          total:
            account.total === 0
              ? 0
              : Number(Helper.realToNumber(String(account.total))),
          discount: Number(Helper.realToNumber(String(account.discount))),
          competence: account.competence
            ? account.competence
            : selectedPaymentDate,
          due_date: account.due_date,
          receive_date: account.receive_date,
          received: account.received,
        };
        dispatch(UtilsActions.setLoading());
        var result = null;
        if (accountId && accountId !== "null") {
          result = await AccountApi.updateAccountReceive({
            id: accountId,
            data: accountData,
          });
        } else result = await AccountApi.createAccount(accountData);

        dispatch(UtilsActions.resetLoading());
        if (result && result.error === false) {
          defineAccountId(result.account_id);
          return true;
        } else {
          dispatch(
            UtilsActions.enqueueSnackbar("error", "Erro ao criar conta!")
          );
          return false;
        }
      }
      // eslint-disable-next-line
    },
    [
      account,
      paidStatus,
      selectedPaymentDate,
      selectedExpirationDate,
      paymentReceiveDate,
      closeDialog,
      openDialog,
      dispatch,
      accountId,
    ]
  );

  const handleAccountCancelStatus = (open) => {
    setShowCancelAccount(open);
  };

  const handleAccountRemoveCancelStatus = (open) => {
    setShowRemoveCancelAccount(open);
  };

  const handleChange = useCallback(
    (event) => {
      let accountData = {
        ...account,
        [event.target.name]: event.target.checked ? 1 : 0,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleInputDescription = useCallback(
    (event) => {
      let accountData = {
        ...account,
        description: event.target.value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleInputAccountType = useCallback(
    (event) => {
      let { name, value } = event.target;
      if (name === "accountTypeId") value = event.target.value;
      let accountData = {
        ...account,
        account_type_id: value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleInputComments = useCallback(
    (event) => {
      let accountData = {
        ...account,
        comments: event.target.value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handlePaymentDateChange = (date, value) => {
    const formattedDate = date;

    let paymentDate = {
      ...account,
      competence: formattedDate,
    };
    setAccount(paymentDate);
    setSelectedPaymentDate(formattedDate);
  };

  const handleExpirationDateChange = (date, value) => {
    if (isValid(date)) {
      const formattedDate = date;

      let paymentDate = {
        ...account,
        due_date: formattedDate,
        old_due_date: account.due_date,
      };
      setAccount(paymentDate);
      setSelectedExpirationDate(formattedDate);

      if (account.batch_id) {
        setShowUpdateDate(true);
      }
    }
  };

  const handlePaymentReceiveDate = (date, value) => {
    const formattedDate = date;

    let paymentDate = {
      ...account,
      receive_date: formattedDate,
    };
    setAccount(paymentDate);
    setPaymentReceiveDate(formattedDate);
  };

  const handleGroupMotherAndChild = useCallback(async () => {
    var TempGroup = await AccountApi.getCategoryList(NEW_QUERY);
    setAllFamilyGrouped(TempGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.category_id]);

  const handleSetRecurrence = useCallback(
    (event) => {
      setRecurrenceType(event);
      setRecurrencePayment(!recurrencePayment);
      let tempISODate = new Date(selectedExpirationDate);
      let variableDay = String(tempISODate.getDate()).padStart(2, "0");
      let variableMonth = String(tempISODate.getMonth() + 1).padStart(2, "0");
      let variableYear = tempISODate.getFullYear();
      let dateDefine = parseISO(
        `${variableYear}-${variableMonth}-${variableDay}`
      );
      let accountData = {
        ...account,
        account_repeat: 1,
        parcels: [
          {
            price: Number(String(account.price)),
            date: dateDefine,
          },
        ],
      };
      setParcelOccurrence(1);
      setAccount(accountData);
    },
    [setRecurrencePayment, recurrencePayment, account, selectedExpirationDate]
  );

  const handleChangePaidStatus = useCallback(() => {
    const newStatus = account.received === 1 ? 0 : 1;
    setAccount((prevAccount) => ({
      ...prevAccount,
      received: newStatus,
      receive_date: new Date(),
    }));
    setPaidStatus(newStatus === 1);
  }, [account.received, setAccount, setPaidStatus]);

  const handleInputAccount = useCallback(
    (event) => {
      let accountTemp = { ...account };
      let { name, value } = event.target;
      if (name === "price") value = Helper.numberToReal(String(value));
      if (name === "discount") value = Helper.numberToReal(String(value));
      if (name === "tax") value = Helper.numberToReal(String(value));
      if (name === "total") value = Helper.numberToReal(String(value));
      const originalPrice =
        Number(Helper.realToNumber(account.price)) +
        Number(Helper.realToNumber(account.discount));
      accountTemp[name] = value;

      if (name !== "total") {
        if (name === "price") {
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(accountTemp.price)) -
              Number(Helper.realToNumber(accountTemp.discount)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
        if (name === "tax") {
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(accountTemp.price)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
        //if(Number(Helper.realToNumber(originalPrice)) >= Number(Helper.realToNumber(accountTemp.discount))){
        if (name === "discount") {
          let newValue = Helper.numberToReal(
            (
              Number(Helper.realToNumber(originalPrice)) -
              Number(Helper.realToNumber(accountTemp.discount))
            ).toFixed(2)
          );
          accountTemp["price"] = newValue;
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(newValue)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
        // }else accountTemp["price"] = originalPrice
      }

      if (
        Number(Helper.realToNumber(accountTemp.price)) +
          Number(Helper.realToNumber(accountTemp.tax)) <
        Number(Helper.realToNumber(accountTemp.discount))
      ) {
        setNegativeValue(colorCancel);
      } else {
        setNegativeValue("#000");
      }
      setAccount(accountTemp);
    },
    [setAccount, account, setNegativeValue]
  );

  const handleGetPaymentMethod = useCallback(
    (event) => {
      let { name, value } = event.target;
      if (name === "paymentReceiveMethod") value = event.target.value;
      let accountData = {
        ...account,
        receive_methods: value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleChangeFrequency = useCallback((event) => {
    let { value } = event.target;
    value = event.target.value;
    setFrequencyState(value);
  }, []);

  const formatNextMonthDate = (date) => {
    let originalDay = new Date(selectedExpirationDate).getDate();
    let nextDate = date;
    let nextDateDay = nextDate.getDate();
    if (nextDateDay !== originalDay) {
      const lastDayofMonth = getDaysInMonth(nextDate);
      if (lastDayofMonth < originalDay) {
        nextDate = endOfMonth(nextDate);
      } else {
        let variableMonth = String(nextDate.getMonth() + 1).padStart(2, "0");
        let variableYear = nextDate.getFullYear();
        let dateString = `${variableYear}-${variableMonth}-${originalDay}`;
        return parseISO(dateString);
      }
    }
    return nextDate;
  };

  const handleParcelsChange = useCallback(
    (event) => {
      let parcelNumber = recurrenceType === "repeat" ? 1 : event;
      let parcelRepeats = [...Array(event).keys()];
      let accountParcels = [];
      let parcelExample;
      let accountData;
      let tempISODate = new Date(selectedExpirationDate);
      let variableDay = String(tempISODate.getDate()).padStart(2, "0");
      let variableMonth = String(tempISODate.getMonth() + 1).padStart(2, "0");
      let variableYear = tempISODate.getFullYear();
      let dateDefine = parseISO(
        `${variableYear}-${variableMonth}-${variableDay}`
      );

      let id = 0;
      let accountPrice = Number(Helper.realToNumber(account.price));

      const parcelPrice = Number((accountPrice / parcelNumber).toFixed(2));
      const parcelPriceTotal = parcelPrice * parcelNumber;

      let lastParcelPrice;
      if (parcelPriceTotal !== accountPrice) {
        let diff = Number((accountPrice - parcelPriceTotal).toFixed(2));
        lastParcelPrice = parcelPrice + diff;
      } else lastParcelPrice = parcelPrice;

      if (frequencyState === 0)
        return dispatch(
          UtilsActions.enqueueSnackbar("warning", "Selecione uma Frequência!")
        );

      if (selectedExpirationDate !== new Date()) {
        if (frequencyState === RECURRENCE_PAYMENT.MONTHLY) {
          dateDefine = subMonths(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = formatNextMonthDate(addMonths(dateDefine, 1));
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.YEARLY) {
          dateDefine = subYears(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addYears(dateDefine, 1);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.WEEKLY) {
          dateDefine = subWeeks(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addWeeks(dateDefine, 1);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.FORTNIGHTLY) {
          dateDefine = subDays(dateDefine, 15);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addDays(dateDefine, 15);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
      }
      setAccount(accountData);
    },
    [dispatch, account, frequencyState, selectedExpirationDate]
  );

  const handleChangeOccurrences = useCallback(
    (event) => {
      let value = Number(event.target.value);
      if (!isNaN(value)) {
        let occurrence = value;
        let accountData = {
          ...account,
          account_repeat: occurrence,
        };
        setParcelOccurrence(occurrence);
        setAccount(accountData);
        handleParcelsChange(Number(occurrence));
      } else {
        dispatch(UtilsActions.enqueueSnackbar("warning", "Valor Inválido"));
      }
    },
    [handleParcelsChange, account]
  );

  const handleChangeDocument = useCallback(
    (event) => {
      let accountData = {
        ...account,
        nf_number: event.target.value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleInputCategory = useCallback(
    (event) => {
      const value = event;
      if (!event) {
        dispatch(
          UtilsActions.enqueueSnackbar(
            "warning",
            "Selecione um Plano de Contas!"
          )
        );
        setAccount((state) => ({
          ...state,
          category_id: 0,
        }));
      }

      if (value) {
        setAccount((state) => ({
          ...state,
          category_id: value,
        }));
      }
    },
    [dispatch]
  );

  const handleParcelEdit = (index, value) => {
    const originalPrice = Number(Helper.realToNumber(account.price));
    const newPrice = Number(Helper.realToNumber(value));

    let updatedParcels = [];

    if (recurrenceType === "parcel") {
      if (newPrice > originalPrice) {
        dispatch(
          UtilsActions.enqueueSnackbar("error", "Valor maior que o Original!")
        );
        return;
      }

      const newParcels = account.parcels.map((parcel, i) => {
        if (i === index) {
          return {
            ...parcel,
            price: newPrice,
            edit: false,
          };
        }
        return parcel;
      });

      const remaingParcels = account.parcels.filter((_, i) => index !== i);
      const editableParcels = account.parcels.filter(
        (parcel, i) => index !== i && parcel.edit
      );

      const remaningSUM = Number(
        (
          remaingParcels.reduce((sum, parcel) => sum + parcel.price, 0) +
          newPrice
        ).toFixed(2)
      );

      const valueTodistribue = Number((originalPrice - remaningSUM).toFixed(2));

      const roundedIndividualValue = Number(
        (valueTodistribue / editableParcels.length).toFixed(2)
      );

      const totalIndividualValue = Number(
        (editableParcels.length * roundedIndividualValue).toFixed(2)
      );

      if (valueTodistribue !== totalIndividualValue) {
        const diff = Number(
          (valueTodistribue - totalIndividualValue).toFixed(2)
        );
        const lastEditParcel = editableParcels[editableParcels.length - 1];

        updatedParcels = newParcels.map((parcel, i) => {
          if (i === lastEditParcel?.id && i !== index) {
            return {
              ...parcel,
              price: Number(
                (parcel.price + roundedIndividualValue + diff).toFixed(2)
              ),
            };
          }
          if (i !== index && parcel?.edit && i !== newParcels.length - 1) {
            return {
              ...parcel,
              price: Number(
                (parcel?.price + roundedIndividualValue).toFixed(2)
              ),
            };
          }
          if (i !== index && parcel?.edit && i === newParcels.length - 1) {
            return {
              ...parcel,
              price: Number(
                (parcel?.price + roundedIndividualValue + diff).toFixed(2)
              ),
            };
          }
          return parcel;
        });
      } else {
        updatedParcels = newParcels.map((parcel, i) => {
          if (i !== index && parcel?.edit) {
            return {
              ...parcel,
              price: parcel.price + roundedIndividualValue,
            };
          }
          return parcel;
        });
      }
    } else {
      const newParcels = account.parcels.map((parcel, i) => {
        if (i === index) {
          return {
            ...parcel,
            price: newPrice,
          };
        }
        return parcel;
      });
      updatedParcels = newParcels;
    }

    setAccount({
      ...account,
      parcels: updatedParcels,
    });
  };

  const changeParcelEditLock = (index) => {
    const newParcels = account.parcels.map((parcel, i) => {
      if (i === index) {
        return {
          ...parcel,
          edit: !parcel.edit,
        };
      }
      return parcel;
    });
    setAccount({
      ...account,
      parcels: newParcels,
    });
  };

  const handleParcelDateChange = (index, value) => {
    const newParcels = account.parcels.map((parcel, i) => {
      if (i === index) {
        return {
          ...parcel,
          date: parseISO(value),
        };
      }
      return parcel;
    });

    setAccount({
      ...account,
      parcels: newParcels,
    });
  };

  const handleTextChange = (index) => (event) => {
    handleParcelEdit(index, Helper.numberToReal(event.target.value));
  };

  const handleInputClient = useCallback(
    (event) => {
      const value = event;
      if (!event)
        return dispatch(
          UtilsActions.enqueueSnackbar("warning", "Selecione um cliente")
        );

      const client = listClientsFormatted.find((client) => client.id === value);

      if (client) {
        setAccount((state) => ({
          ...state,
          client_id: client.id,
          bill: client.bill,
        }));
      } else {
        setAccount((state) => ({
          ...state,
          client_id: account.id,
          bill: 0,
        }));
      }
    },
    [listClientsFormatted, account, dispatch]
  );

  const handleCancel = () => {
    history(ROUTE.FINANCIAL.ACCOUNT);
  };

  const verifyAccountDate = (date) => {
    if (date) {
      if (isDate(date)) {
        return date;
      } else return parseISO(date);
    } else return selectedPaymentDate;
  };

  useEffect(() => {
    let sum = 0.0;
    if (account?.parcels) {
      sum = account?.parcels?.reduce(
        (sum, parcel) => sum + (parcel.price || 0),
        0
      );
    }
    setParcelTotal(Number(sum.toFixed(2)));
  }, [account]);

  useEffect(() => {
    async function fetchData() {
      if (accountId) {
        dispatch(UtilsActions.setLoading());
        let tempId = await AccountApi.getAccountId(accountId);
        if (tempId.data == null) {
          return history(ROUTE.FINANCIAL.ACCOUNT);
        }
        if (Number(tempId?.data?.account_receife?.total) < 0) {
          setNegativeValue(colorCancel);
        }
        setAccordionReceive(tempId.data.account_receife.received);
        setAccordionLaunch(!tempId.data.account_receife.received);
        setAccordionComment(tempId.data.account_receife.comments?.length > 0);
        setAccount({
          account_id: tempId.data.id,
          account_type_id: tempId.data.account_receife.accountTypeId,
          client_id: tempId.data.account_receife.client_id,
          description: tempId.data.account_receife.description,
          competence: tempId.data.account_receife.competence,
          account_repeat: tempId.data.account_receife.account_repeat,
          due_date: tempId.data.account_receife.due_date
            ? parse(
                tempId.data.account_receife.due_date,
                "yyyy-MM-dd",
                new Date()
              )
            : null,
          comments: tempId.data.account_receife.comments,
          price: Helper.numberToReal(tempId.data.account_receife.price),
          category_id: tempId.data.account_receife.category_id,
          nf_number: tempId.data.account_receife.nf_number,
          batch_id: tempId.data.account_receife.batch_id,
          receive_date: tempId.data.account_receife.receive_date
            ? parse(
                tempId.data.account_receife.receive_date,
                "yyyy-MM-dd",
                new Date()
              )
            : null,
          closure_client_id: tempId.data.account_receife.closure_client_id,
          kind_of: account.kind_of,
          discount:
            tempId?.data?.account_receife?.discount === 0 ||
            tempId?.data?.account_receife?.discount === null
              ? "0,00"
              : Helper.numberToReal(tempId?.data?.account_receife?.discount),
          tax:
            tempId?.data?.account_receife?.tax === 0 ||
            tempId?.data?.account_receife?.tax === null
              ? "0,00"
              : Helper.numberToReal(tempId?.data?.account_receife?.tax),
          total: tempId.data.account_receife.total
            ? Helper.numberToReal(tempId.data.account_receife.total)
            : "0,00",
          total_history: tempId.data.account_receife.total
            ? tempId.data.account_receife.total
            : "0,00",
          received: tempId.data.account_receife.received,
          receive_methods: tempId.data.account_receife.receive_methods,
          cancel_reason: tempId.data.cancel_reason,
          cancel_observation: tempId.data.cancel_observation,
          cancel_message: tempId.data.cancel_message,
          childrens: tempId.data.childrens,
          bill: tempId?.data?.account_receife?.client?.bill,
        });
        dispatch(UtilsActions.resetLoading());
      }
    }
    if (state.auth.token) {
      fetchData();
      handleGroupMotherAndChild();
      dispatch(AuxActions.getClients());
      dispatch(AuxActions.getProviders());
      dispatch(AuxActions.getAccountTypes());
      handleGetDomains();
    }

    dispatch(UtilsActions.resetLoading());
  }, [state.auth]);

  const category = allFamilyGrouped?.data?.find(
    (category) => category.id === account.category_id
  );

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "#ccc",
        },
      }}
    >
      {showPartial && (
        <DialogPartialReceive
          open={showPartial}
          onClose={savePartial}
          closeDialog={handleClose}
          createAccount={handleSubmit}
          account={account}
        ></DialogPartialReceive>
      )}
      {showCancelAccount && (
        <CancelAccount
          open={showCancelAccount}
          accountId={accountId}
          close={handleAccountCancelStatus}
        ></CancelAccount>
      )}
      {showRemoveCancelAccount && (
        <RemoveCancelAccount
          open={showRemoveCancelAccount}
          account={account}
          close={handleAccountRemoveCancelStatus}
        ></RemoveCancelAccount>
      )}
      {showUpdateDate && (
        <UpdateAllAccounts
          newDate={selectedExpirationDate}
          account={account}
          showDialog={showUpdateDate}
          setShowDialog={setShowUpdateDate}
        ></UpdateAllAccounts>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogtitle}>
              Conta a Receber:{" "}
              {account.description ? account.description : "Sem título"}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={10} lg={8} sm={12}>
            <Card>
              <CardContent>
                <Grid container item direction="column">
                  {account.partial && (
                    <Alert severity="warning" align="center">
                      Esta é uma nova conta gerada com base no recebimento
                      parcial
                    </Alert>
                  )}
                  {ACCOUNT_STATUS.some(
                    (item) =>
                      item.name === "Cancelada" && item.id == account.received
                  ) && (
                    <Alert severity="error" align="center">
                      <strong>CONTA CANCELADA</strong> -{" "}
                      {account.cancel_message}
                    </Alert>
                  )}
                  <Accordion
                    elevation={0}
                    expanded={accordionInfo}
                    onChange={() => setAccordionInfo(!accordionInfo)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Informações da conta
                      </Typography>
                    </AccordionSummary>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={4} xs-direction="column">
                        <Autocomplete
                          name="clientId"
                          fullWidth
                          value={
                            listClientsFormatted?.find(
                              (client) => client.id === (account.client_id ?? 0)
                            ) || null
                          }
                          options={listClientsFormatted ?? []}
                          disabled={account.received === 1}
                          getOptionLabel={(client) => client?.name}
                          noOptionsText={"Nenhum cliente encontrado."}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Clientes"
                              variant="outlined"
                            />
                          )}
                          onChange={(event, client) =>
                            handleInputClient(client !== null ? client.id : 0)
                          }
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name="uselessName"
                          value={account.description}
                          label="Descrição da conta"
                          onChange={handleInputDescription}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          variant="outlined"
                          fullWidth
                          inputVariant="outlined"
                          InputLabelProps={{ shrink: true }}
                          disabled={account.received === 1}
                          label="Data vencimento"
                          value={
                            account.due_date
                              ? account.due_date
                              : selectedExpirationDate
                          }
                          format="dd/MM/yyyy"
                          onChange={handleExpirationDateChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="price"
                          disabled={account.received === 1}
                          label="Valor"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.price}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.gridPadding}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel id="select-contract">
                            Forma de Pagamento
                          </InputLabel>
                          <Select
                            disabled={account.received === 1}
                            required
                            name="paymentReceiveMethod"
                            label="Forma de Pagamento"
                            onChange={handleGetPaymentMethod}
                            value={account.receive_methods}
                          >
                            {paymentMethods?.data?.map((type) => (
                              <MenuItem key={type.id} value={type.id}>
                                {type.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={3} className={classes.gridPadding}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                      >
                        <FormLabel color={colorText}>Centro de Custo</FormLabel>
                        <RadioGroup name="expirationDay" value={0} row>
                          <FormControlLabel
                            key={0}
                            value={0}
                            control={<Radio color="primary" />}
                            label={"RC"}
                          />
                          <FormControlLabel
                            key={1}
                            value={1}
                            control={<Radio color="primary" />}
                            label={"CP"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid> */}
                    </Grid>
                  </Accordion>
                  <Accordion
                    elevation={0}
                    expanded={accordionLaunch}
                    onChange={() => setAccordionLaunch(!accordionLaunch)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Lançamento da conta
                      </Typography>
                    </AccordionSummary>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel id="select-accountType">Conta</InputLabel>
                          <Select
                            required
                            name="accountTypeId"
                            labelId="select-accountType"
                            label="Conta"
                            onChange={handleInputAccountType}
                            value={account.account_type_id}
                            disabled={account.received === 1}
                          >
                            <MenuItem value="0">
                              <em>Nenhuma</em>
                            </MenuItem>
                            {auxAccountTypes.map((accountType) => (
                              <MenuItem
                                key={accountType.id}
                                value={accountType.id}
                              >
                                {accountType.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Autocomplete
                          name="category"
                          value={category || null}
                          options={allFamilyGrouped?.data?.filter(
                            (category) =>
                              category.kind_of === 0 &&
                              category.parent_id !== null
                          )}
                          getOptionLabel={(category) => category.title}
                          noOptionsText={"Nenhuma Plano de Conta encontrado."}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plano de Contas"
                              variant="outlined"
                              defaultValue={category?.title}
                            />
                          )}
                          onChange={(event, category) =>
                            handleInputCategory(
                              category !== null ? category.id : 0
                            )
                          }
                          disabled={account.received === 1}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          fullWidth
                          variant="filled"
                          inputVariant="outlined"
                          label="Data competência"
                          value={verifyAccountDate(account.competence)}
                          format="dd/MM/yyyy"
                          onChange={handlePaymentDateChange}
                          disabled={account.received === 1}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          name="Número NF/Fatura"
                          label="Número NF/Fatura"
                          onChange={handleChangeDocument}
                          value={account.nf_number}
                          helperText={
                            <span>
                              Cliente
                              {account.bill === 0 ? (
                                " NÃO DESEJA "
                              ) : (
                                <span style={{ color: "darkgreen" }}>
                                  {" "}
                                  DESEJA{" "}
                                </span>
                              )}
                              receber fatura
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Accordion>
                  <Accordion
                    elevation={0}
                    expanded={accordionComment}
                    onChange={() => setAccordionComment(!accordionComment)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Comentário
                      </Typography>
                    </AccordionSummary>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={account.comments ? 3 : 1}
                      name="description"
                      label="Insira um comentário..."
                      value={account.comments}
                      onChange={handleInputComments}
                    />
                  </Accordion>
                  <Accordion
                    elevation={0}
                    expanded={accordionRecurence}
                    onChange={() => setAccordionRecurence(!accordionRecurence)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Recorrência
                      </Typography>
                    </AccordionSummary>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <Checkbox
                            style={{ alignItems: "center" }}
                            onChange={() => handleSetRecurrence("parcel")}
                            checked={
                              recurrenceType === "parcel" &&
                              recurrencePayment === true
                            }
                            color="primary"
                            disabled={account.received === 1}
                          />
                          Parcelar{" "}
                          <Checkbox
                            style={{ alignItems: "center" }}
                            onChange={() => handleSetRecurrence("repeat")}
                            checked={
                              recurrenceType === "repeat" &&
                              recurrencePayment === true
                            }
                            color="primary"
                            disabled={account.received === 1}
                          />
                          Repetir{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          disabled={!recurrencePayment}
                          required={recurrencePayment}
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel id="select-contract">
                            Frequência
                          </InputLabel>
                          <Select
                            required
                            name="frequencySelect"
                            label="Frequência"
                            onChange={handleChangeFrequency}
                          >
                            {RECURRENCE_PAYMENT.ARRAY.map((type) => (
                              <MenuItem key={type.id} value={type.id}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled={!recurrencePayment || frequencyState === 0}
                          required={recurrencePayment}
                          onChange={handleChangeOccurrences}
                          value={account.account_repeat || parcelOccurrence}
                          label="Ocorrência"
                        />
                      </Grid>
                      {recurrenceType === "parcel" && (
                        <Grid item xs={2}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={parcelTotal}
                            label="Valor das Parcelas"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      )}
                      <Grid container item spacing={2} xs={12}>
                        {recurrencePayment &&
                          account.parcels?.map((e, index) => (
                            <Grid
                              item
                              xs={2}
                              direction="row"
                              key={"parcels" + index}
                            >
                              <Paper
                                style={{ backgroundColor: colorSecondary }}
                                className={classes.paper}
                              >
                                <Grid container alignItems="center">
                                  <Grid item xs={9}>
                                    <Typography variant="h6">
                                      {index + 1 + "/" + account.account_repeat}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Button
                                      style={{
                                        minWidth: "auto",
                                        width: "auto",
                                      }}
                                      onClick={() =>
                                        changeParcelEditLock(index)
                                      }
                                    >
                                      <Icon
                                        style={{ fontSize: "20px" }}
                                        className={
                                          e.edit
                                            ? "fas fa-unlock"
                                            : "fas fa-lock"
                                        }
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                                <TextField
                                  value={Helper.numberToReal(
                                    String(e.price.toFixed(2))
                                  )}
                                  onChange={handleTextChange(index)}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        R$
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <InputBase
                                  type="date"
                                  value={format(
                                    new Date(e?.date),
                                    "yyyy-MM-dd"
                                  )}
                                  onChange={(event) =>
                                    handleParcelDateChange(
                                      index,
                                      event.target.value
                                    )
                                  }
                                />
                              </Paper>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Accordion>
                  <Accordion
                    elevation={0}
                    expanded={accordionReceive}
                    onChange={() => setAccordionReceive(!accordionReceive)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Dados de Pagamento
                      </Typography>
                    </AccordionSummary>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <Checkbox
                            style={{ alignItems: "center" }}
                            onChange={handleChangePaidStatus}
                            checked={
                              paidStatus === true || account.received === 1
                            }
                            color="primary"
                          />
                          Recebido{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          fullWidth
                          variant="outlined"
                          label="Dt. Pagamento"
                          inputVariant="outlined"
                          InputLabelProps={{ shrink: true }}
                          disabled={!paidStatus && account.received !== 1}
                          value={
                            account.receive_date
                              ? account.receive_date
                              : paymentReceiveDate
                          }
                          format="dd/MM/yyyy"
                          onChange={handlePaymentReceiveDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          // disabled={!paidStatus && account.received !== 1}
                          name="discount"
                          label="Descontos/Taxas"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.discount}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          // disabled={!paidStatus && account.received !== 1}
                          name="tax"
                          label="Juros/Multas"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.tax}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled={!paidStatus && account.received !== 1}
                          name="total"
                          label="Valor pago"
                          onChange={handleInputAccount}
                          InputProps={{
                            style: { color: negativeValue },
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.total}
                        />
                      </Grid>
                    </Grid>
                  </Accordion>
                  <Accordion
                    elevation={0}
                    expanded={expanded}
                    // onChange={toggleAccordion} TODO: Descomentar o código assim que concluir a funcionalidade
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Contas Relacionadas
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      children={
                        <div style={{ width: "100%" }}>
                          <AccountAssociateTable
                            data={account}
                            accountId={accountId}
                            childrensTemp={account.childrens}
                          />
                        </div>
                      }
                    />
                  </Accordion>
                </Grid>
              </CardContent>
            </Card>
            <Grid container direction="row">
              <Grid item container xs={6} justifyContent="flex-start">
                <DialogActions>
                  {accountId &&
                    !ACCOUNT_STATUS.some(
                      (item) =>
                        item.name === "Cancelada" && item.id == account.received
                    ) && (
                      <Button
                        classes={{
                          root: clsx(classes.buttonRed),
                        }}
                        variant="contained"
                        onClick={() => handleAccountCancelStatus(true)}
                      >
                        Cancelar Conta
                      </Button>
                    )}
                  {accountId &&
                    ACCOUNT_STATUS.some(
                      (item) =>
                        item.name === "Cancelada" && item.id == account.received
                    ) && (
                      <AccessControl
                        permissionsRequired={[PERMISSION.FINANCIAL_MASTER]}
                      >
                        <Button
                          classes={{
                            root: clsx(classes.buttonRed),
                          }}
                          variant="contained"
                          onClick={() => handleAccountRemoveCancelStatus(true)}
                        >
                          Descancelar
                        </Button>
                      </AccessControl>
                    )}
                </DialogActions>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end">
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => validateReceiveAccount()}
                    variant="contained"
                    color="primary"
                  >
                    {accountId ? "Atualizar" : "Confirmar"}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
};

export default AccountCreateReceive;
