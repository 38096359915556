import { colors } from "@material-ui/core";

// -------------------------------------------------- IMPORTANT
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const AMBIENT_ENV = process.env.NODE_ENV;
export const LOCAL_STORAGE = "@OLSystem";

// export const SAGA_AUX_WAITING_TIME = 1000 * 60 * 30; // 30 min
export const SAGA_AUX_WAITING_TIME = 1000 * 60 * 0; // 0 min

const BACKEND = process.env.REACT_APP_API_BASEURL;

export const API = {
  BACKEND: BACKEND,
  UPLOAD: BACKEND + "/uploads",
  UPLOAD_AIDA: BACKEND + "/uploads/aida",
};

export const PERMISSION = {
  ADMIN: "ADMIN",
  AUDIT: "AUDIT",
  BRAND: "BRAND",
  BRAND_EDIT: "BRAND_EDIT",
  CALL: "CALL",
  CALL_MASTER: "CALL_MASTER",
  CALL_EDIT: "CALL_EDIT",
  CHARGE_CANCEL: "CHARGE_CANCEL",
  KNOWLEDGES: "KNOWLEDGES",
  KNOWLEDGES_MASTER: "KNOWLEDGES_MASTER",
  KNOWLEDGES_EDIT: "KNOWLEDGES_EDIT",
  CATEGORY: "CATEGORY",
  CATEGORY_EDIT: "CATEGORY_EDIT",
  CLIENT: "CLIENT",
  CLIENT_EDIT: "CLIENT_EDIT",
  CONFIGURATION: "CONFIGURATION",
  CONFIGURATION_EDIT: "CONFIGURATION_EDIT",
  FINANCIAL: "FINANCIAL",
  FINANCIAL_MASTER: "FINANCIAL_MASTER",
  HOME: "HOME",
  PROVIDER: "PROVIDER",
  MENU: "MENU",
  MODEL: "MODEL",
  MODEL_EDIT: "MODEL_EDIT",
  PRODUCT: "PRODUCT",
  PURCHASE: "PURCHASE",
  PURCHASE_EDIT: "PURCHASE_EDIT",
  PRODUCT_EDIT: "PRODUCT_EDIT",
  PROPOSAL: "PROPOSAL",
  PROPOSAL_EDIT: "PROPOSAL_EDIT",
  QUOTATION: "QUOTATION",
  QUOTATION_EDIT: "QUOTATION_EDIT",
  QUOTATIONPRODUCT: "QUOTATIONPRODUCT_EDIT",
  QUOTATIONPRODUCT_EDIT: "QUOTATIONPRODUCT_EDIT",
  REPORT: "REPORT",
  SERVICE: "SERVICE",
  SERVICE_EDIT: "SERVICE_EDIT",
  SERVICE_ORDER: "SERVICE_ORDER",
  SERVICE_ORDER_EDIT: "SERVICE_ORDER_EDIT",
  SERVICE_ORDER_MASTER: "SERVICE_ORDER_MASTER",
  SERVICE_ORDER_RESET: "SERVICE_ORDER_RESET",
  USER: "USER",
  GROUP: "GROUP",
  USER_EDIT: "USER_EDIT",
};

export const ROUTE = {
  HOME: "/",
  BRANDS: "/marcas",
  PROVIDERS: "/fornecedores",
  CATEGORIES: "/categorias",
  // CATEGORY_FRAMES: '/categoria/estruturas',
  CLIENTS: "/clientes",
  CLIENT_DETAILS: "/cliente/detalhes",
  CLIENT_FINANCIAL_REPORT: "/extrato/cliente",
  CLIENT_EXTERNAL: "/cadastro",
  CONFIGURATIONS: "/configuracoes",
  DOCUMENT: "/documento",
  LOGIN: "/entrar",
  MENU: "/menu",
  MODELS: "/modelos",
  PRODUCTS: "/produtos",
  PROPOSALS: "/pedidos",
  PROPOSAL_CREATE: "/pedido/novo",
  PROPOSAL_EDIT: "/pedido/editar",
  PROPOSAL_FILE: "/pedido/arquivo",
  PURCHASES: "/compras",
  REPORTS: "/relatorios",
  REPORT_INVENTORY: "/relatorio/estoque",
  REPORT_RETURNS: "/relatorio/devolucao",
  REPORT_RENTAL: "/relatorio/locacao",
  REPORT_CALLS: "/relatorio/chamados",
  REPORT_CLIENT_INVENTORY: "/relatorio/clientes",
  REPORT_PRODUCTION: "/relatorio/producao",
  REPORT_CITY: "/relatorio/cidades",
  REPORT_PRODUCTS_CITY: "/relatorio/categoriaxcidade",
  REPORT_EXPORT_SALES: "/relatorio/exportar_vendas",
  REPORT_ADDRESSES: "/relatorio/enderecos",
  REPORT_MONTHLY: "/relatorio/mensal",
  SUCCESS_EXTERNAL: "/obrigado",
  USERS: "/funcionarios",
  SQUADS: "/grupos",
  SERVICES: "/servicos",
  SERVICE_ORDERS: "/os",
  CALL: "/chamados",
  CALL_DETAILS: "/chamado",
  AUDIT: "/auditorias",
  AUDIT_DETAILS: "/auditoria",
  AUDIT_FILE: "/laudo",
  KNOWLEDGES: "/conhecimento",
  SERVICE_ORDER_RESET: "/os/resetar",
  SERVICE_ORDER_SPLIT: "/os/dividir",
  QUOTATION_HOME: "/proposta",
  QUOTATION_DASHBOARD: "/proposta/dashboard",
  QUOTATION: {
    VIEW: "/proposta/visualizar",
    QUOTATION_PRODUCTS: "/produtos",
    QUOTATION_PARTNERS: "/parceiros",
  },
  FINANCIAL_HOME: "/financeiro",
  FINANCIAL: {
    FINANCIAL_CLOSURE: "/fechamento",
    FINANCIAL_CLOSURE_DETAILS: "/fechamento/detalhes",
    FINANCIAL_CLOSURE_CLIENT_BATCH: "/fechamento/pagamentos",
    DRE: "/dre",
    ACCOUNT: "/contas",
    ACCOUNT_CREATE_RECEIVE: "/contas/receber/novo",
    ACCOUNT_RECEIVE_EDIT: "/contas/receber/editar",
    ACCOUNT_CREATE_PAYMENT: "/contas/pagar/novo",
    ACCOUNT_PAYMENT_EDIT: "/contas/pagar/editar",
    ACCOUNT_PLANS: "/contas/plano-de-contas",
    ACCOUNT_TYPES_CREDIT_CARD: "/contas-bancarias/cartao",
    ACCOUNT_TYPES_BANKS: "/contas-bancarias/banco",
    ACCOUNT_TYPES_CREATE_EDIT: "/contas-bancarias/novo",
    ACCOUNT_TYPES: "/contas-bancarias",
    BILLS: "/faturas",
    CHARGE: "/cobranca",
  },
  SUB_BRANCHES: "/segmento",
  PATRIMONY: "/patrimonio",
  // CONTRACT_DOC: '/documento/contrato',
};

export const MENU_LINKS = {
  [PERMISSION.HOME]: {
    name: "Home",
    icon: "fas fa-home",
    to: ROUTE.HOME,
    permission: null,
  },
  [PERMISSION.BRAND]: {
    name: "Marcas",
    icon: "fas fa-trademark",
    to: ROUTE.BRANDS,
    permission: PERMISSION.BRAND,
  },
  [PERMISSION.PROVIDER]: {
    name: "Fornecedores",
    icon: "fas fa-address-book",
    to: ROUTE.PROVIDERS,
    permission: PERMISSION.PROVIDER,
  },
  [PERMISSION.CATEGORY]: {
    name: "Categorias",
    icon: "fas fa-cubes",
    to: ROUTE.CATEGORIES,
    permission: PERMISSION.CATEGORY,
  },
  [PERMISSION.CLIENT]: {
    name: "Clientes",
    icon: "fas fa-users",
    to: ROUTE.CLIENTS,
    permission: PERMISSION.CLIENT,
  },
  [PERMISSION.CONFIGURATION]: {
    name: "Configurações",
    icon: "fas fa-cogs",
    to: ROUTE.CONFIGURATIONS,
    permission: PERMISSION.CONFIGURATION,
  },
  [PERMISSION.MODEL]: {
    name: "Modelos",
    icon: "fas fa-cube",
    to: ROUTE.MODELS,
    permission: PERMISSION.MODEL,
  },
  [PERMISSION.PRODUCT]: {
    name: "Produtos",
    icon: "fas fa-laptop",
    to: ROUTE.PRODUCTS,
    permission: PERMISSION.PRODUCT,
  },
  [PERMISSION.PROPOSAL]: {
    name: "Pedidos",
    icon: "fas fa-money-check-alt",
    to: ROUTE.PROPOSALS,
    permission: PERMISSION.PROPOSAL,
  },
  [PERMISSION.PURCHASE]: {
    name: "Compras",
    icon: "fas fa-shopping-basket",
    to: ROUTE.PURCHASES,
    permission: PERMISSION.PURCHASE,
  },
  [PERMISSION.REPORT]: {
    name: "Relatórios",
    icon: "fas fa-chart-line",
    to: ROUTE.REPORTS,
    permission: PERMISSION.REPORT,
  },
  // [PERMISSION.SERVICE]: { name: 'Serviços', icon: "fas fa-briefcase", to: ROUTE.SERVICES, permission: PERMISSION.SERVICE, },
  [PERMISSION.SERVICE_ORDER]: {
    name: "Ordens de Serviço",
    icon: "fas fa-tools",
    to: ROUTE.SERVICE_ORDERS,
    permission: PERMISSION.SERVICE_ORDER,
  },
  [PERMISSION.SERVICE_ORDER_RESET]: {
    name: "Lote Reset",
    icon: "fas fa-times",
    to: ROUTE.SERVICE_ORDER_RESET,
    permission: PERMISSION.ADMIN,
  },
  [PERMISSION.USER]: {
    name: "Funcionários",
    icon: "fas fa-id-card",
    to: ROUTE.USERS,
    permission: PERMISSION.USER,
  },
  [PERMISSION.GROUP]: {
    name: "Grupos",
    icon: "fas fa-id-card",
    to: ROUTE.SQUADS,
    permission: PERMISSION.GROUP,
  },
  [PERMISSION.CALL]: {
    name: "Chamados",
    icon: "fas fa-comments",
    to: ROUTE.CALL,
    permission: PERMISSION.CALL,
  },
  [PERMISSION.KNOWLEDGES]: {
    name: "Base de conhecimento",
    icon: "fas fa-book-open",
    to: ROUTE.KNOWLEDGES,
    permission: PERMISSION.KNOWLEDGES,
  },
  // [PERMISSION.AUDIT]: {
  //   name: "Auditorias",
  //   icon: "fas fa-clipboard-check",
  //   to: ROUTE.AUDIT,
  //   permission: PERMISSION.AUDIT,
  // },
  [PERMISSION.FINANCIAL]: {
    name: "Módulo Financeiro",
    icon: "fas fa-dollar-sign",
    to: ROUTE.FINANCIAL_HOME,
    permission: PERMISSION.FINANCIAL,
  },
  [PERMISSION.CLIENT_EDIT]: {
    name: "Segmento",
    icon: "fas fa-code-branch",
    to: ROUTE.SUB_BRANCHES,
    permission: PERMISSION.CLIENT_EDIT,
  },
};

export const REPORT_LINKS = [
  {
    name: "Devoluções",
    to: ROUTE.REPORT_RETURNS,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Estoque",
    to: ROUTE.REPORT_INVENTORY,
    permission: PERMISSION.PRODUCT_EDIT,
  },
  {
    name: "Locação",
    to: ROUTE.REPORT_RENTAL,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Chamados",
    to: ROUTE.REPORT_CALLS,
    permission: PERMISSION.CALL_MASTER,
  },
  {
    name: "Cliente",
    to: ROUTE.REPORT_CLIENT_INVENTORY,
    permission: PERMISSION.CLIENT_EDIT,
  },
  {
    name: "Produção",
    to: ROUTE.REPORT_PRODUCTION,
    permission: PERMISSION.USER_EDIT,
  },
  {
    name: "Cidades",
    to: ROUTE.REPORT_CITY,
    permission: PERMISSION.CLIENT_EDIT,
  },
  {
    name: "Endereços",
    to: ROUTE.REPORT_ADDRESSES,
    permission: PERMISSION.CLIENT_EDIT,
  },
  {
    name: "Exportar Vendas",
    to: ROUTE.REPORT_EXPORT_SALES,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Gerencial Mensal",
    to: ROUTE.REPORT_MONTHLY,
    permission: PERMISSION.ADMIN,
  },
  /* { name: 'Categoria por Cidade', to: ROUTE.REPORT_PRODUCTS_CITY, }, */
];

export const FINANCIAL_LINKS = [
  {
    name: "Fechamento",
    icon: "fas fa-coins",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.FINANCIAL_CLOSURE,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Faturas",
    icon: "fas fa-receipt",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.BILLS,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Contas a Receber/Pagar",
    icon: "fas fa-dollar-sign",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.ACCOUNT,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Contas Bancárias",
    icon: "fas fa-piggy-bank",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.ACCOUNT_TYPES,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Plano de Contas",
    icon: "fas fa-file-invoice-dollar",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.ACCOUNT_PLANS,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "DRE",
    icon: "fas fa-chart-area",
    to: ROUTE.FINANCIAL_HOME + ROUTE.FINANCIAL.DRE,
    permission: PERMISSION.FINANCIAL,
  },
  {
    name: "Relatórios",
    icon: "fas fa-chart-line",
    to: ROUTE.REPORTS,
    permission: PERMISSION.REPORT,
  },
];

// -------------------------------------------------- DEFAULTS

export const ACTIVE_EXTERNAL_LINK_DOCS = 1;

export const SERVICE_ORDER_TYPE = {
  PRINCIPAL: 1,
  ADDITIONAL: 2,
};

export const SERVICE_ORDER_SUBMIT_TYPE = {
  CONFIRM_AND_STOP: 0,
  CONFIRM: 1,
};

// const SERVICE_ORDER_CODE = {
//     DP_COMERCIAL: { id: 'DP_COMERCIAL', name: 'De/Para Comercial' },
//     DP_TECNICO: { id: 'DP_TECNICO', name: 'De/Para Técnico' },
//     ESTOQUE: { id: 'ESTOQUE', name: 'Estoque' },
//     MONTAGEM: { id: 'MONTAGEM', name: 'Montagem' },
//     QUALIDADE: { id: 'QUALIDADE', name: 'Qualidade' },
//     ENTREGA: { id: 'ENTREGA', name: 'Entrega' },
//     FIM: { id: 'FIM', name: 'Fim' },
//     ARRAY: [],
//     RENTAL: {},
//     SALE: {},
// };
// SERVICE_ORDER_CODE.ARRAY = [
//     SERVICE_ORDER_CODE.DP_COMERCIAL,
//     SERVICE_ORDER_CODE.DP_TECNICO,
//     SERVICE_ORDER_CODE.ESTOQUE,
//     SERVICE_ORDER_CODE.MONTAGEM,
//     SERVICE_ORDER_CODE.QUALIDADE,
//     SERVICE_ORDER_CODE.ENTREGA
// ];
// SERVICE_ORDER_CODE.RENTAL = {
//     [SERVICE_ORDER_CODE.DP_COMERCIAL.id]: { sequence: 1, next: SERVICE_ORDER_CODE.DP_TECNICO.id },
//     [SERVICE_ORDER_CODE.DP_TECNICO.id]: { sequence: 2, next: SERVICE_ORDER_CODE.ESTOQUE.id },
//     [SERVICE_ORDER_CODE.ESTOQUE.id]: { sequence: 3, next: SERVICE_ORDER_CODE.MONTAGEM.id },
//     [SERVICE_ORDER_CODE.MONTAGEM.id]: { sequence: 4, next: SERVICE_ORDER_CODE.QUALIDADE.id },
//     [SERVICE_ORDER_CODE.QUALIDADE.id]: { sequence: 5, next: SERVICE_ORDER_CODE.ENTREGA.id },
//     [SERVICE_ORDER_CODE.ENTREGA.id]: { sequence: 6, next: SERVICE_ORDER_CODE.FIM.id },
//     [SERVICE_ORDER_CODE.FIM.id]: { sequence: 7, next: null },
//     ARRAY: [],
// }
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.DP_COMERCIAL.id]);
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.DP_TECNICO.id]);
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.ESTOQUE.id]);
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.MONTAGEM.id]);
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.QUALIDADE.id]);
// SERVICE_ORDER_CODE.RENTAL.ARRAY.push(SERVICE_ORDER_CODE.RENTAL[SERVICE_ORDER_CODE.ENTREGA.id]);
// SERVICE_ORDER_CODE.SALE = {
//     [SERVICE_ORDER_CODE.DP_COMERCIAL.id]: { sequence: 1, next: SERVICE_ORDER_CODE.DP_TECNICO.id },
//     [SERVICE_ORDER_CODE.DP_TECNICO.id]: { sequence: 2, next: SERVICE_ORDER_CODE.ESTOQUE.id },
//     [SERVICE_ORDER_CODE.ESTOQUE.id]: { sequence: 3, next: SERVICE_ORDER_CODE.MONTAGEM.id },
//     [SERVICE_ORDER_CODE.MONTAGEM.id]: { sequence: 4, next: SERVICE_ORDER_CODE.QUALIDADE.id },
//     [SERVICE_ORDER_CODE.QUALIDADE.id]: { sequence: 5, next: SERVICE_ORDER_CODE.ENTREGA.id },
//     [SERVICE_ORDER_CODE.ENTREGA.id]: { sequence: 6, next: SERVICE_ORDER_CODE.FIM.id },
//     [SERVICE_ORDER_CODE.FIM.id]: { sequence: 7, next: null },
//     ARRAY: [],
// }
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.DP_COMERCIAL.id]);
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.DP_TECNICO.id]);
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.ESTOQUE.id]);
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.MONTAGEM.id]);
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.QUALIDADE.id]);
// SERVICE_ORDER_CODE.SALE.ARRAY.push(SERVICE_ORDER_CODE.SALE[SERVICE_ORDER_CODE.ENTREGA.id]);
// export { SERVICE_ORDER_CODE };
const AUDIT_STATUS = {
  CANCELED: 2,
  CANCELED_NAME: "Cancelado",
  PROCESS: 1,
  PROCESS_NAME: "Aberto",
  END: 0,
  END_NAME: "Fechado",
  ARRAY: [],
};
AUDIT_STATUS.ARRAY.push({
  id: AUDIT_STATUS.PROCESS,
  name: AUDIT_STATUS.PROCESS_NAME,
});
AUDIT_STATUS.ARRAY.push({
  id: AUDIT_STATUS.CANCELED,
  name: AUDIT_STATUS.CANCELED_NAME,
});
AUDIT_STATUS.ARRAY.push({ id: AUDIT_STATUS.END, name: AUDIT_STATUS.END_NAME });
export { AUDIT_STATUS };

const BILLS_STATUS = {
  TO_RECEIVE: 0,
  TO_RECEIVE_NAME: "A Receber",
  TO_PAY: 1,
  TO_PAY_NAME: "A Pagar",
  /* TRANSFERS: 2,
    TRANSFERS_NAME: 'Transferência',
    REGISTER_BILLS: 3,
    REGISTER_BILLS_NAME: 'Cadastrar Conta', */
  ARRAY: [],
};
BILLS_STATUS.ARRAY.push({
  id: BILLS_STATUS.TO_RECEIVE,
  name: BILLS_STATUS.TO_RECEIVE_NAME,
});
BILLS_STATUS.ARRAY.push({
  id: BILLS_STATUS.TO_PAY,
  name: BILLS_STATUS.TO_PAY_NAME,
});
/* BILLS_STATUS.ARRAY.push({ id: BILLS_STATUS.TRANSFERS, name: BILLS_STATUS.TRANSFERS_NAME });
BILLS_STATUS.ARRAY.push({ id: BILLS_STATUS.REGISTER_BILLS, name: BILLS_STATUS.REGISTER_BILLS_NAME }); */
export { BILLS_STATUS };

const PAYMENT_DOMAINS = {
  PIX: 1,
  BOLETO: 2,
  TRANSFERENCIA: 3,
  CARTÃO: 4,
  DINHEIRO: 5,
  PIX_NAME: "PIX",
  BOLETO_NAME: "BOLETO",
  TRANSFERENCIA_NAME: "TRANSFERÊNCIA",
  CARTÃO_NAME: "CARTÃO",
  DINHEIRO_NAME: "DINHEIRO",
  ARRAY: [],
};
PAYMENT_DOMAINS.ARRAY.push({
  id: PAYMENT_DOMAINS.PIX,
  name: PAYMENT_DOMAINS.PIX_NAME,
});
PAYMENT_DOMAINS.ARRAY.push({
  id: PAYMENT_DOMAINS.BOLETO,
  name: PAYMENT_DOMAINS.BOLETO_NAME,
});
PAYMENT_DOMAINS.ARRAY.push({
  id: PAYMENT_DOMAINS.TRANSFERENCIA,
  name: PAYMENT_DOMAINS.TRANSFERENCIA_NAME,
});
PAYMENT_DOMAINS.ARRAY.push({
  id: PAYMENT_DOMAINS.CARTÃO,
  name: PAYMENT_DOMAINS.CARTÃO_NAME,
});
PAYMENT_DOMAINS.ARRAY.push({
  id: PAYMENT_DOMAINS.DINHEIRO,
  name: PAYMENT_DOMAINS.DINHEIRO_NAME,
});
export { PAYMENT_DOMAINS };

const RECURRENCE_PAYMENT = {
  YEARLY: 1,
  YEARLY_NAME: "ANUAL",
  MONTHLY: 2,
  MONTHLY_NAME: "MENSAL",
  FORTNIGHTLY: 3,
  FORTNIGHTLY_NAME: "QUINZENAL",
  WEEKLY: 4,
  WEEKLY_NAME: "SEMANAL",
  ARRAY: [],
};
RECURRENCE_PAYMENT.ARRAY.push({
  id: RECURRENCE_PAYMENT.YEARLY,
  name: RECURRENCE_PAYMENT.YEARLY_NAME,
});
RECURRENCE_PAYMENT.ARRAY.push({
  id: RECURRENCE_PAYMENT.MONTHLY,
  name: RECURRENCE_PAYMENT.MONTHLY_NAME,
});
RECURRENCE_PAYMENT.ARRAY.push({
  id: RECURRENCE_PAYMENT.FORTNIGHTLY,
  name: RECURRENCE_PAYMENT.FORTNIGHTLY_NAME,
});
RECURRENCE_PAYMENT.ARRAY.push({
  id: RECURRENCE_PAYMENT.WEEKLY,
  name: RECURRENCE_PAYMENT.WEEKLY_NAME,
});
export { RECURRENCE_PAYMENT };

const CALL_STATUS = {
  OPEN: 1,
  OPEN_NAME: "Aberto",
  PAUSED: 2,
  PAUSED_NAME: "Pausado",
  CLOSED: 0,
  CLOSED_NAME: "Fechado",
  ARRAY: [],
};
CALL_STATUS.ARRAY.push({ id: CALL_STATUS.OPEN, name: CALL_STATUS.OPEN_NAME });
CALL_STATUS.ARRAY.push({
  id: CALL_STATUS.PAUSED,
  name: CALL_STATUS.PAUSED_NAME,
});
CALL_STATUS.ARRAY.push({
  id: CALL_STATUS.CLOSED,
  name: CALL_STATUS.CLOSED_NAME,
});
export { CALL_STATUS };

const CALL_TYPE = {
  INCIDENTS: "0",
  INCIDENTS_NAME: "Cliente",
  INTERNAL: "1",
  INTERNAL_NAME: "Interno",
  ARRAY: [],
};
CALL_TYPE.ARRAY.push({
  id: CALL_TYPE.INCIDENTS,
  name: CALL_TYPE.INCIDENTS_NAME,
});
CALL_TYPE.ARRAY.push({ id: CALL_TYPE.INTERNAL, name: CALL_TYPE.INTERNAL_NAME });
export { CALL_TYPE };

const CLIENT_BATCH_PRODUCT_STATUS = {
  DEFAULT: "99",
  DEFAULT_NAME: "Todos",
  RETURNED: "0",
  RETURNED_NAME: "Devoluções",
  ON_CLIENT: "2",
  ON_CLIENT_NAME: "Locados",
  /*     ON_PROCESS: '3',
    ON_PROCESS_NAME: 'EM PROCESSO', */
  ARRAY: [],
};
CLIENT_BATCH_PRODUCT_STATUS.ARRAY.push({
  id: CLIENT_BATCH_PRODUCT_STATUS.DEFAULT,
  name: CLIENT_BATCH_PRODUCT_STATUS.DEFAULT_NAME,
});
CLIENT_BATCH_PRODUCT_STATUS.ARRAY.push({
  id: CLIENT_BATCH_PRODUCT_STATUS.RETURNED,
  name: CLIENT_BATCH_PRODUCT_STATUS.RETURNED_NAME,
});
CLIENT_BATCH_PRODUCT_STATUS.ARRAY.push({
  id: CLIENT_BATCH_PRODUCT_STATUS.ON_CLIENT,
  name: CLIENT_BATCH_PRODUCT_STATUS.ON_CLIENT_NAME,
});
/* CLIENT_BATCH_PRODUCT_STATUS.ARRAY.push({ id: CLIENT_BATCH_PRODUCT_STATUS.ON_PROCESS, name: CLIENT_BATCH_PRODUCT_STATUS.ON_PROCESS_NAME }); */
export { CLIENT_BATCH_PRODUCT_STATUS };

const AUDIT_TYPE = {
  INPUT: "0",
  INPUT_NAME: "Auditoria de saída/entrada",
  RETURN: "1",
  RETURN_NAME: "Auditoria de devolução",
  ARRAY: [],
};
AUDIT_TYPE.ARRAY.push({ id: AUDIT_TYPE.INPUT, name: AUDIT_TYPE.INPUT_NAME });
AUDIT_TYPE.ARRAY.push({ id: AUDIT_TYPE.RETURN, name: AUDIT_TYPE.RETURN_NAME });
export { AUDIT_TYPE };

const CALL_SEVERITY = {
  LOW: "1",
  LOW_NAME: "Baixa",
  AVERAGE: "2",
  AVERAGE_NAME: "Média",
  HIGH: "3",
  HIGH_NAME: "Alta",
  CRITICAL: "4",
  CRITICAL_NAME: "Critica",
  ARRAY: [],
};
CALL_SEVERITY.ARRAY.push({
  id: CALL_SEVERITY.AVERAGE,
  name: CALL_SEVERITY.AVERAGE_NAME,
});
CALL_SEVERITY.ARRAY.push({
  id: CALL_SEVERITY.HIGH,
  name: CALL_SEVERITY.HIGH_NAME,
});
CALL_SEVERITY.ARRAY.push({
  id: CALL_SEVERITY.CRITICAL,
  name: CALL_SEVERITY.CRITICAL_NAME,
});
CALL_SEVERITY.ARRAY.push({
  id: CALL_SEVERITY.LOW,
  name: CALL_SEVERITY.LOW_NAME,
});
export { CALL_SEVERITY };

export const CALL_PURCHASE_STATUS = {
  NOT_PURCHASE: 0,
  COMPLETE: 1,
  IN_PROGRESS: 2,
};

export const SERVICE_ORDER_STATUS = {
  COMPLETE: 1,
  COMPLETE_NAME: "Concluído",
  CANCELED: 2,
  CANCELED_NAME: "Cancelado",
  WAITING: 0,
  WAITING_NAME: "Em processo",
  PAUSED: 3,
  PAUSED_NAME: "Em espera",
};

export const SERVICE_ORDER_PURCHASE_STATUS = {
  NOT_PURCHASE: 0,
  COMPLETE: 1,
  IN_PROGRESS: 2,
};

export const BATCH_PRODUCT_STATUS = {
  COMPLETED: 0,
  ONLOC: 1,
  CLIENT: 2,
  PROCESS: 3,
  RELOCATION: 4,
};

export const BATCH_STATUS = {
  WAITING: 0,
  WAITING_NAME: "Ativo",
  COMPLETED: 1,
  COMPLETED_NAME: "Completo",
};

export const CLOSURE_STATUS = {
  INITIAL: 1,
  CLOSE: 2,
};

export const CLOSURE_BATCH_STATUS = {
  INITIAL: 1,
  BILLABLE: 2,
  NOT_BILLABLE: 3,
};

export const QUOTATION_STATUS = {
  SENT: 0,
  CANCELED: 1,
  ORDER: 2,
};

const ACCOUNT_TYPE_TYPEOF = {
  BANK: 0,
  BANK_NAME: "Banco",
  CREDIT_CARD: 1,
  CREDIT_CARD_NAME: "Cartão de Crédito",
  ARRAY: [],
};
ACCOUNT_TYPE_TYPEOF.ARRAY.push({
  id: ACCOUNT_TYPE_TYPEOF.BANK,
  name: ACCOUNT_TYPE_TYPEOF.BANK_NAME,
});
ACCOUNT_TYPE_TYPEOF.ARRAY.push({
  id: ACCOUNT_TYPE_TYPEOF.CREDIT_CARD,
  name: ACCOUNT_TYPE_TYPEOF.CREDIT_CARD_NAME,
});
export { ACCOUNT_TYPE_TYPEOF };

export const BREAK_LINE = "\n";

const DOCUMENT_TYPES = {
  CONTRACT_NORMAL: 1,
  CONTRACT_NORMAL_NAME: "Contrato Normal",
  CONTRACT_NORMAL_INITIAL: "N",
  CONTRACT_SERVER: 2,
  CONTRACT_SERVER_NAME: "Contrato Servidor",
  CONTRACT_SERVER_INITIAL: "S",
  DREAF: 3,
  DREAF_NAME: "DREAF",
  DELIVERY_TERM: 4,
  DELIVERY_TERM_NAME: "Termo de Entrega",
  WITHDRAW_TERM: 5,
  WITHDRAW_TERM_NAME: "Termo de Retirada",
  RETURN_TERM: 6,
  RETURN_TERM_NAME: "TDEL",
  CHANGE_TERM: 7,
  CHANGE_TERM_NAME: "TSEL",
  QUOTATION: "Proposta Comercial",
  REPLACEMENT_TERM: 8,
  REPLACEMENT_TERM_NAME: "Termo de Substituição",
  TECHNICAL_REPORT: 9,
  TECHNICAL_REPORT_NAME: "Laudo Técnico",
  UPGRADE_TERM: 10,
  UPGRADE_TERM_NAME: "TUEL",
  UPGRADE_REPLACEMENT_TERM: 11,
  UPGRADE_REPLACEMENT_TERM_NAME: "Termo de Upgrade",
  ARRAY: [],
  CONTRACT_ARRAY: [],
};
DOCUMENT_TYPES.ARRAY = [
  {
    id: DOCUMENT_TYPES.CONTRACT_NORMAL,
    name: DOCUMENT_TYPES.CONTRACT_NORMAL_NAME,
  },
  {
    id: DOCUMENT_TYPES.CONTRACT_SERVER,
    name: DOCUMENT_TYPES.CONTRACT_SERVER_NAME,
  },
  { id: DOCUMENT_TYPES.DREAF, name: DOCUMENT_TYPES.DREAF_NAME },
  { id: DOCUMENT_TYPES.DELIVERY_TERM, name: DOCUMENT_TYPES.DELIVERY_TERM_NAME },
  { id: DOCUMENT_TYPES.WITHDRAW_TERM, name: DOCUMENT_TYPES.WITHDRAW_TERM_NAME },
  { id: DOCUMENT_TYPES.RETURN_TERM, name: DOCUMENT_TYPES.RETURN_TERM_NAME },
  { id: DOCUMENT_TYPES.CHANGE_TERM, name: DOCUMENT_TYPES.CHANGE_TERM_NAME },
  {
    id: DOCUMENT_TYPES.REPLACEMENT_TERM,
    name: DOCUMENT_TYPES.REPLACEMENT_TERM_NAME,
  },
  {
    id: DOCUMENT_TYPES.TECHNICAL_REPORT,
    name: DOCUMENT_TYPES.TECHNICAL_REPORT_NAME,
  },
  { id: DOCUMENT_TYPES.UPGRADE_TERM, name: DOCUMENT_TYPES.UPGRADE_TERM_NAME },
  {
    id: DOCUMENT_TYPES.UPGRADE_REPLACEMENT_TERM,
    name: DOCUMENT_TYPES.UPGRADE_REPLACEMENT_TERM_NAME,
  },
];
DOCUMENT_TYPES.CONTRACT_ARRAY = [
  {
    id: DOCUMENT_TYPES.CONTRACT_NORMAL,
    name: DOCUMENT_TYPES.CONTRACT_NORMAL_NAME,
  },
  {
    id: DOCUMENT_TYPES.CONTRACT_SERVER,
    name: DOCUMENT_TYPES.CONTRACT_SERVER_NAME,
  },
];
export { DOCUMENT_TYPES };

export const CLIENT_EXPIRATION_DAY = [1, 5, 10, 15, 20, 25, 30];

export const CLIENT_EXPIRATION_DAY_DEFAULT = 10;

const CLIENT_PRO_SCORE = {
  NOT_ANALYZED: -1,
  NOT_ANALYZED_NAME: "Não Analisado",
  DENIED: 0,
  DENIED_NAME: "Negado",
  APPROVED: 1,
  APPROVED_NAME: "Aprovado",
  ARRAY: [],
};
CLIENT_PRO_SCORE.ARRAY.push({
  id: CLIENT_PRO_SCORE.NOT_ANALYZED,
  name: CLIENT_PRO_SCORE.NOT_ANALYZED_NAME,
});
CLIENT_PRO_SCORE.ARRAY.push({
  id: CLIENT_PRO_SCORE.DENIED,
  name: CLIENT_PRO_SCORE.DENIED_NAME,
});
CLIENT_PRO_SCORE.ARRAY.push({
  id: CLIENT_PRO_SCORE.APPROVED,
  name: CLIENT_PRO_SCORE.APPROVED_NAME,
});
export { CLIENT_PRO_SCORE };

const CLIENT_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  INCOMPLETE_NAME: "Incompleto",
  FINANCIAL: "FINANCIAL",
  FINANCIAL_NAME: "Financeiro",
  ACTIVE: "ACTIVE",
  ACTIVE_NAME: "Ativo",
  INACTIVE: "INACTIVE",
  INACTIVE_NAME: "Inativo",
  DEFAULTER: "DEFAULTER",
  DEFAULTER_NAME: "Inadimplente",
  PENDING_DEBT: "PENDING_DEBT",
  PENDING_DEBT_NAME: "Débito Pendente",
  ARRAY: [],
};
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.INCOMPLETE,
  name: CLIENT_STATUS.INCOMPLETE_NAME,
});
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.FINANCIAL,
  name: CLIENT_STATUS.FINANCIAL_NAME,
});
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.ACTIVE,
  name: CLIENT_STATUS.ACTIVE_NAME,
});
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.INACTIVE,
  name: CLIENT_STATUS.INACTIVE_NAME,
});
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.DEFAULTER,
  name: CLIENT_STATUS.DEFAULTER_NAME,
});
CLIENT_STATUS.ARRAY.push({
  id: CLIENT_STATUS.PENDING_DEBT,
  name: CLIENT_STATUS.PENDING_DEBT_NAME,
});
export { CLIENT_STATUS };

export const CLIENT_USER_TYPES = [
  { id: 1, name: "Responsável de Contrato" },
  { id: 2, name: "Responsável de TI" },
  { id: 3, name: "TI" },
  { id: 4, name: "Técnico" },
  { id: 5, name: "Financeiro" },
  { id: 6, name: "Contato" },
  { id: 99, name: "Outros" },
];

export const CLIENT_ADDRESS_USER_TYPE = {
  PRINCIPAL: 1,
  NORMAL: 0,
};

export const CLIENT_USER_RECEIVER = {
  TRUE: 1,
  FALSE: 0,
};

export const CONFIGURATION_PARAMETERS = {
  CUSTO_DE_CAPTACAO: "custo_de_captacao",
  IOF: "iof",
  MARGEM_DE_CAPTACAO: "margem_de_captacao",
  MARGEM_DE_LUCRO: "margem_de_lucro",
  PIS_COFINS: "pis_cofins",
  ONLOC_DATA: "onloc_data",
};

export const CONFIG_ONLOC_USER = {
  ALEXANDRE: "ALEXANDRE",
  YASMIN: "YASMIN",
};

export const CONFIGURATION_TYPES = {
  INT: "INT",
  STRING: "STRING",
  DECIMAL: "DECIMAL",
  ARRAY: ["INT", "STRING", "DECIMAL"],
};

export const DEFAULT_COUNT = {
  showing: 0,
  filtered: 0,
  total: 0,
};

export const DEFAULT_QUERY = {
  search: "",
  searchSelectedId: 0,
  page: 1,
  rowsPerPage: 25,
  orderColumn: "name",
  orderOrientation: "asc",
};
export const DEFAULT_ROWS_PER_PAGE_ARRAY = [
  { id: 25, name: "25 / Página" },
  { id: 50, name: "50 / Página" },
  { id: 75, name: "75 / Página" },
  { id: 100, name: "100 / Página" },
  // { id: -1, name: "Todos" },
];

const FILE_MIMETYPES = {
  PDF: "application/pdf",
  WORD: "application/msword",
  JPEG: "image/jpeg",
  PNG: "image/png",
  GIF: "image/gif",
  BMP: "image/bmp",
  CSV: "text/csv",
  TXT: "text/plain",
  ARRAY: [],
};
FILE_MIMETYPES.ARRAY.push(
  FILE_MIMETYPES.PDF,
  FILE_MIMETYPES.WORD,
  FILE_MIMETYPES.JPEG,
  FILE_MIMETYPES.PNG,
  FILE_MIMETYPES.GIF,
  FILE_MIMETYPES.BMP,
  FILE_MIMETYPES.CSV,
  FILE_MIMETYPES.TXT
);
export { FILE_MIMETYPES };

export const IBGE_STATES = [
  {
    id: 12,
    sigla: "AC",
    nome: "Acre",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 27,
    sigla: "AL",
    nome: "Alagoas",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 16,
    sigla: "AP",
    nome: "Amapá",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 13,
    sigla: "AM",
    nome: "Amazonas",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 29,
    sigla: "BA",
    nome: "Bahia",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 23,
    sigla: "CE",
    nome: "Ceará",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 53,
    sigla: "DF",
    nome: "Distrito Federal",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    id: 32,
    sigla: "ES",
    nome: "Espírito Santo",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    id: 52,
    sigla: "GO",
    nome: "Goiás",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    id: 21,
    sigla: "MA",
    nome: "Maranhão",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 51,
    sigla: "MT",
    nome: "Mato Grosso",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    id: 50,
    sigla: "MS",
    nome: "Mato Grosso do Sul",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    id: 31,
    sigla: "MG",
    nome: "Minas Gerais",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    id: 15,
    sigla: "PA",
    nome: "Pará",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 25,
    sigla: "PB",
    nome: "Paraíba",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 41,
    sigla: "PR",
    nome: "Paraná",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    id: 26,
    sigla: "PE",
    nome: "Pernambuco",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 22,
    sigla: "PI",
    nome: "Piauí",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 33,
    sigla: "RJ",
    nome: "Rio de Janeiro",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    id: 24,
    sigla: "RN",
    nome: "Rio Grande do Norte",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 43,
    sigla: "RS",
    nome: "Rio Grande do Sul",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    id: 11,
    sigla: "RO",
    nome: "Rondônia",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 14,
    sigla: "RR",
    nome: "Roraima",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    id: 42,
    sigla: "SC",
    nome: "Santa Catarina",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    id: 35,
    sigla: "SP",
    nome: "São Paulo",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    id: 28,
    sigla: "SE",
    nome: "Sergipe",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    id: 17,
    sigla: "TO",
    nome: "Tocantins",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
];

const PROPOSAL_ITEM_TYPE = {
  PRODUCT: 1,
  PRODUCT_NAME: "PRODUTO",
  SERVICE: 2,
  SERVICE_NAME: "SERVIÇO",
  ARRAY: [],
};
PROPOSAL_ITEM_TYPE.ARRAY.push(PROPOSAL_ITEM_TYPE.PRODUCT);
PROPOSAL_ITEM_TYPE.ARRAY.push(PROPOSAL_ITEM_TYPE.SERVICE);
export { PROPOSAL_ITEM_TYPE };

const MODEL_TYPE = {
  COMPONENT: 1,
  COMPONENT_NAME: "Componente",
  ASSEMBLED: 2,
  ASSEMBLED_NAME: "Montado",
  ARRAY: [],
};
MODEL_TYPE.ARRAY.push({
  id: MODEL_TYPE.COMPONENT,
  name: MODEL_TYPE.COMPONENT_NAME,
});
MODEL_TYPE.ARRAY.push({
  id: MODEL_TYPE.ASSEMBLED,
  name: MODEL_TYPE.ASSEMBLED_NAME,
});
export { MODEL_TYPE };

const PRODUCT_DESTINY = {
  AVAILABLE: 1,
  AVAILABLE_NAME: "Ok",
  DISCARD: 2,
  DISCARD_NAME: "Descarte",
  ARRAY: [],
};
PRODUCT_DESTINY.ARRAY.push({
  id: PRODUCT_DESTINY.AVAILABLE,
  name: PRODUCT_DESTINY.AVAILABLE_NAME,
});
PRODUCT_DESTINY.ARRAY.push({
  id: PRODUCT_DESTINY.DISCARD,
  name: PRODUCT_DESTINY.DISCARD_NAME,
});
export { PRODUCT_DESTINY };

const PRODUCT_STATUS = {
  ACTIVE: 1,
  ACTIVE_NAME: "Ativo",
  INACTIVE: 0,
  INACTIVE_NAME: "Inativo",
  MAINTENANCE: 2,
  MAINTENANCE_NAME: "Manutenção",
  SALE: 3,
  SALE_NAME: "Venda",
  ARRAY: [],
};
PRODUCT_STATUS.ARRAY.push({
  id: PRODUCT_STATUS.ACTIVE,
  name: PRODUCT_STATUS.ACTIVE_NAME,
});
PRODUCT_STATUS.ARRAY.push({
  id: PRODUCT_STATUS.INACTIVE,
  name: PRODUCT_STATUS.INACTIVE_NAME,
});
PRODUCT_STATUS.ARRAY.push({
  id: PRODUCT_STATUS.MAINTENANCE,
  name: PRODUCT_STATUS.MAINTENANCE_NAME,
});
PRODUCT_STATUS.ARRAY.push({
  id: PRODUCT_STATUS.SALE,
  name: PRODUCT_STATUS.SALE_NAME,
});
export { PRODUCT_STATUS };

export const PRODUCT_QUALITY = ["A", "B", "C"];

export const SO_OPTIONS = [
  "NENHUM",
  "LINUX",
  "WINDOWS SERVER",
  "WINDOWS 11",
  "WINDOWS 10",
  "WINDOWS 7",
  "VMWARE",
];

export const PROPOSAL_INSTALLMENTS = 36;

const PROPOSAL_STATUS = {
  WAITING: 0,
  WAITING_NAME: "Aguardando",
  APPROVED: 1,
  APPROVED_NAME: "Aprovado",
  CANCELED: 2,
  CANCELED_NAME: "Cancelado",
  APPROVED_WAITING_CLIENT: 3,
  APPROVED_WAITING_CLIENT_NAME: "Aguardando dados do cliente",
  ARRAY: [],
};
PROPOSAL_STATUS.ARRAY.push({
  id: PROPOSAL_STATUS.WAITING,
  name: PROPOSAL_STATUS.WAITING_NAME,
});
PROPOSAL_STATUS.ARRAY.push({
  id: PROPOSAL_STATUS.APPROVED,
  name: PROPOSAL_STATUS.APPROVED_NAME,
});
PROPOSAL_STATUS.ARRAY.push({
  id: PROPOSAL_STATUS.CANCELED,
  name: PROPOSAL_STATUS.CANCELED_NAME,
});
PROPOSAL_STATUS.ARRAY.push({
  id: PROPOSAL_STATUS.APPROVED_WAITING_CLIENT,
  name: PROPOSAL_STATUS.APPROVED_WAITING_CLIENT_NAME,
});
export { PROPOSAL_STATUS };

const PROPOSAL_TYPE = {
  RENTAL: "RENTAL",
  RENTAL_NAME: "Locação",
  SALE: "SALE",
  SALE_NAME: "Venda",
  ARRAY: [],
};
PROPOSAL_TYPE.ARRAY.push({
  id: PROPOSAL_TYPE.RENTAL,
  name: PROPOSAL_TYPE.RENTAL_NAME,
});
// PROPOSAL_TYPE.ARRAY.push({ id: PROPOSAL_TYPE.SALE, name: PROPOSAL_TYPE.SALE_NAME });
export { PROPOSAL_TYPE };

const PURCHASE_STATUS = {
  WAITING: 0,
  WAITING_NAME: "Aguardando",
  PROCESS: 1,
  PROCESS_NAME: "Em Processo",
  COMPLETE: 2,
  COMPLETE_NAME: "Concluído",
  CANCELED: 3,
  CANCELED_NAME: "Cancelado",
  ARRAY: [],
};
PURCHASE_STATUS.ARRAY.push({
  id: PURCHASE_STATUS.NOT_PURCHASED,
  name: PURCHASE_STATUS.NOT_PURCHASED_NAME,
});
PURCHASE_STATUS.ARRAY.push({
  id: PURCHASE_STATUS.WAITING,
  name: PURCHASE_STATUS.WAITING_NAME,
});
PURCHASE_STATUS.ARRAY.push({
  id: PURCHASE_STATUS.COMPLETE,
  name: PURCHASE_STATUS.COMPLETE_NAME,
});
PURCHASE_STATUS.ARRAY.push({
  id: PURCHASE_STATUS.CANCELED,
  name: PURCHASE_STATUS.CANCELED_NAME,
});
export { PURCHASE_STATUS };

export const USER_COLORS = [
  { name: "Preto", hexa: "#000000" },
  { name: "Vermelho", hexa: colors.red[500] },
  { name: "Rosa", hexa: colors.pink[500] },
  { name: "Roxo", hexa: colors.purple[500] },
  { name: "Roxo Escuro", hexa: colors.deepPurple[500] },
  { name: "Anil", hexa: colors.indigo[500] },
  { name: "Azul", hexa: colors.blue[500] },
  { name: "Azul Claro", hexa: colors.lightBlue[500] },
  { name: "Ciano", hexa: colors.cyan[500] },
  { name: "Verde Água", hexa: colors.teal[500] },
  { name: "Verde", hexa: colors.green[500] },
  { name: "Verde Claro", hexa: colors.lightGreen[500] },
  { name: "Lima", hexa: colors.lime[500] },
  { name: "Amarelo", hexa: colors.yellow[500] },
  { name: "Âmbar", hexa: colors.amber[500] },
  { name: "Laranja", hexa: colors.orange[500] },
  { name: "Laranja Escuro", hexa: colors.deepOrange[500] },
  { name: "Marrom", hexa: colors.brown[500] },
  { name: "Cinza", hexa: colors.grey[500] },
  { name: "Azul Acinzentado", hexa: colors.blueGrey[500] },
];

const CLIENT_BRANCH = {
  BUSINESS: 0,
  BUSINESS_NAME: "Comércio",
  INDUSTRY: 1,
  INDUSTRY_NAME: "Indústria",
  SERVICE: 2,
  SERVICE_NAME: "Serviços",
  OTHER: 3,
  OTHER_NAME: "Outros",
  ARRAY: [],
};
CLIENT_BRANCH.ARRAY.push({
  id: CLIENT_BRANCH.BUSINESS,
  name: CLIENT_BRANCH.BUSINESS_NAME,
});
CLIENT_BRANCH.ARRAY.push({
  id: CLIENT_BRANCH.INDUSTRY,
  name: CLIENT_BRANCH.INDUSTRY_NAME,
});
CLIENT_BRANCH.ARRAY.push({
  id: CLIENT_BRANCH.SERVICE,
  name: CLIENT_BRANCH.SERVICE_NAME,
});
CLIENT_BRANCH.ARRAY.push({
  id: CLIENT_BRANCH.OTHER,
  name: CLIENT_BRANCH.OTHER_NAME,
});
export { CLIENT_BRANCH };

const CUSTOMER_RESPONSIBLE = {
  ALEXANDRE: 0,
  ALEXANDRE_NAME: "ALEXANDRE",
  ALEXANDRE_COLOR: colors.teal[500],
  YASMIN: 1,
  YASMIN_NAME: "YASMIN",
  YASMIN_COLOR: colors.red[500],
  JUNIOR: 2,
  JUNIOR_NAME: "JUNIOR",
  JUNIOR_COLOR: colors.indigo[500],
  DIEGO: 4,
  DIEGO_NAME: "DIEGO",
  DIEGO_COLOR: colors.amber[500],
  KEFFERSON: 6,
  KEFFERSON_NAME: "KEFFERSON",
  KEFFERSON_COLOR: colors.green[500],
  EMILLY: 7,
  EMILLY_NAME: "EMILLY",
  EMILLY_COLOR: colors.pink[500],
  ARRAY: [],
};
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.ALEXANDRE,
  name: CUSTOMER_RESPONSIBLE.ALEXANDRE_NAME,
  color: CUSTOMER_RESPONSIBLE.ALEXANDRE_COLOR,
});
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.YASMIN,
  name: CUSTOMER_RESPONSIBLE.YASMIN_NAME,
  color: CUSTOMER_RESPONSIBLE.YASMIN_COLOR,
});
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.JUNIOR,
  name: CUSTOMER_RESPONSIBLE.JUNIOR_NAME,
  color: CUSTOMER_RESPONSIBLE.JUNIOR_COLOR,
});
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.DIEGO,
  name: CUSTOMER_RESPONSIBLE.DIEGO_NAME,
  color: CUSTOMER_RESPONSIBLE.DIEGO_COLOR,
});
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.KEFFERSON,
  name: CUSTOMER_RESPONSIBLE.KEFFERSON_NAME,
  color: CUSTOMER_RESPONSIBLE.KEFFERSON_COLOR,
});
CUSTOMER_RESPONSIBLE.ARRAY.push({
  id: CUSTOMER_RESPONSIBLE.EMILLY,
  name: CUSTOMER_RESPONSIBLE.EMILLY_NAME,
  color: CUSTOMER_RESPONSIBLE.EMILLY_COLOR,
});
export { CUSTOMER_RESPONSIBLE };

const CONTRACT_RESPONSIBLE = {
  ALEXANDRE: 0,
  ALEXANDRE_NAME: "ALEXANDRE",
  YASMIN: 1,
  YASMIN_NAME: "YASMIN",
  ARRAY: [],
};
CONTRACT_RESPONSIBLE.ARRAY.push({
  id: CONTRACT_RESPONSIBLE.ALEXANDRE,
  name: CONTRACT_RESPONSIBLE.ALEXANDRE_NAME,
});
CONTRACT_RESPONSIBLE.ARRAY.push({
  id: CONTRACT_RESPONSIBLE.YASMIN,
  name: CONTRACT_RESPONSIBLE.YASMIN_NAME,
});
export { CONTRACT_RESPONSIBLE };

const RELOCATION_TYPE = {
  BATCH_RELOCATION: 0,
  PRODUCT_RELOCATION: 1,
  ARRAY: [],
};
RELOCATION_TYPE.ARRAY.push({
  BATCH_RELOCATION_NAME: "REMANEJAMENTO DE LOTE",
  BATCH_RELOCATION: 0,
});
RELOCATION_TYPE.ARRAY.push({
  PRODUCT_RELOCATION_NAME: "REMANEJAMENTO DE PRODUTOS",
  PRODUCT_RELOCATION: 1,
});
export { RELOCATION_TYPE };

const ACCOUNT_TYPEOF = {
  RECEIVE: 0,
  PAYMENT: 1,
  RECEIVE_NAME: "A RECEBER",
  PAYMENT_NAME: "A PAGAR",
  ARRAY: [],
};
ACCOUNT_TYPEOF.ARRAY.push({
  id: ACCOUNT_TYPEOF.RECEIVE,
  name: ACCOUNT_TYPEOF.RECEIVE_NAME,
});
ACCOUNT_TYPEOF.ARRAY.push({
  id: ACCOUNT_TYPEOF.PAYMENT,
  name: ACCOUNT_TYPEOF.PAYMENT_NAME,
});
export { ACCOUNT_TYPEOF };

export const ACCOUNT_STATUS = [
  { id: 0, name: "Em aberto" },
  { id: 1, name: "Pago" },
  { id: 2, name: "Cancelada" },
];

export const START_PAGE = [{ role: "vendedor", page: ROUTE.QUOTATION_HOME }];
