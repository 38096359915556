import {
  parseISO,
  isAfter,
  isBefore,
  isEqual,
  endOfToday,
  startOfToday,
  isWeekend,
} from "date-fns";
import extenso from "extenso";
import { ptBR } from "date-fns/locale";

export const checkLogin = () => {
  const userKey = localStorage.getItem("@mrauthadm");
  if (userKey) return true;
  return false;
};

export const currency = (number) => {
  if (number === undefined) number = 0;
  return number.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const numberToReal = (number) => {
  // const haveDecimal = number.includes('.');
  let v = number.replace(/\D/g, "");
  // if (haveDecimal) {
  v = (v / 100).toFixed(2) + "";
  // } else {
  // 	v = Number(v).toFixed(2) + "";
  // }
  v = v.replace(".", ",");
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return v;
};

export const formatNumberToReal = (number, type = "currency") => {
  const formattedResult = new Intl.NumberFormat("pt-BR", {
    style: type,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "BRL",
  }).format(number);
  return formattedResult;
};

export const formatRoundDecimal = (num) => {
  return (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2);
};

export const formatIntervalToDuration = (miliseconds = 0) => {
  const dateObj = {
    days: Math.floor(miliseconds / 86400000),
    hours: Math.floor(miliseconds / 3600000) % 24,
    minutes: Math.floor(miliseconds / 60000) % 60,
    seconds: Math.floor(miliseconds / 1000) % 60,
  };

  let duration = "";
  if (dateObj.years)
    duration = dateObj.years ? `${dateObj.years} ano(s), ` : "";
  duration += dateObj.months ? `${dateObj.months} mês(es), ` : "";
  duration += dateObj.days ? `${dateObj.days} dia(s), ` : "";
  duration +=
    String(dateObj.hours).padStart(2, "0") +
    ":" +
    String(dateObj.minutes).padStart(2, "0") +
    ":" +
    String(dateObj.seconds).padStart(2, "0");

  return duration;
};

export const dateTimeGetFullDate = (date) => {
  let dateTemp = new Date(date.split("T")[0]);
  return dateTemp;
};

export const dateTimeGetFullYear = (date) => {
  let dateTemp = new Date(date.split("T")[0]);
  return dateTemp.getFullYear();
};

export const dateTimeGetMonth = (date) => {
  let dateTemp = new Date(date.split("T")[0]);
  return dateTemp.getMonth() + 1;
};

export const getActualTwoDigitMonth = () => {
  let monthSet = new Date().getMonth() + 1;
  let dataTemp;

  monthSet > 9
    ? (dataTemp = new Date().getMonth() + 1)
    : (dataTemp = `0${new Date().getMonth() + 1}`);
  return dataTemp;
};

export const transformTwoDigitMonth = (fullDate) => {
  let monthSet = new Date(fullDate).getMonth() + 1;
  let dataTemp;

  monthSet > 9
    ? (dataTemp = monthSet)
    : (dataTemp = `0${new Date(fullDate).getMonth() + 1}`);
  return dataTemp;
};

export const getNewDateConvert = (fullDate) => {
  let dataTemp;
  try {
    let monthSet = new Date(fullDate).getMonth() + 1;
    let yearSet = new Date(fullDate).getFullYear();
    let daySet = new Date(fullDate).getDate();

    monthSet > 9
      ? (dataTemp = `${daySet}/${monthSet}/${yearSet}`)
      : (dataTemp = `${daySet}/0${
          new Date(fullDate).getMonth() + 1
        }/${yearSet}`);
  } catch (err) {
    let monthSet = new Date().getMonth() + 1;
    let yearSet = new Date().getFullYear();
    let daySet = new Date().getDate();

    monthSet > 9
      ? (dataTemp = `${daySet}/${monthSet}/${yearSet}`)
      : (dataTemp = `${daySet}/0${monthSet}/${yearSet}`);
  }
  return dataTemp;
};

export const dateCompare = (date, compare) => {
  const currentDate = new Date();
  const dateToCompare = new Date(date);
  const differenceInDays = Math.floor(
    (dateToCompare - currentDate) / (1000 * 60 * 60 * 24)
  );

  return differenceInDays <= compare;
};

export const dateDifference = (dateString, compare, type = "days") => {
  let date = new Date(dateString);
  let dateToCompare = new Date(compare);

  if (type === "days") {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor((dateToCompare - date) / millisecondsPerDay);
    return diffInDays;
  }

  if (type === "months") {
    const diffInMonths =
      dateToCompare.getMonth() +
      12 * dateToCompare.getFullYear() -
      (date.getMonth() + 12 * date.getFullYear());
    return diffInMonths;
  }

  if (type === "years") {
    const diffInYears = dateToCompare.getFullYear() - date.getFullYear();
    return diffInYears;
  }

  return null; // Valor de retorno padrão caso o tipo não seja suportado
};

export const splitDateGet = (date) => {
  let day;
  let month;
  let year;
  let fullDate;
  if (date.split("-")[0].length === 4) {
    year = date.split("-")[0];
    month = date.split("-")[1];
    day = date.split("-")[2];

    fullDate = `${day}/${month}/${year}`;
  } else {
    year = date.split("-")[2];
    month = date.split("-")[1];
    day = date.split("-")[0];

    fullDate = `${day}/${month}/${year}`;
  }

  return fullDate;
};

export const dateTimeGetDate = (date) => {
  let dateTemp = new Date(date.split("T")[0]);
  return dateTemp.getDate();
};

export const calculeMonthDiference = (date1, date2) => {
  var formattedDate1;
  var formattedDate2 = date2 ? date2 : null;

  if (String(date1).includes("T")) {
    formattedDate1 = dateTimeGetFullDate(date1);
  } else {
    formattedDate1 = new Date(date1);
  }
  if (String(date2).includes("T")) {
    formattedDate2 = dateTimeGetFullDate(date2);
  } else {
    formattedDate2 === null
      ? (formattedDate2 = new Date())
      : (formattedDate2 = new Date(date2));
  }

  var total =
    (formattedDate2.getFullYear() - formattedDate1.getFullYear()) * 12 +
    (formattedDate2.getMonth() - formattedDate1.getMonth());

  return total;
};

export const calculeDaysDiference = (date1, date2) => {
  var formattedDate1;
  var formattedDate2 = date2 ? date2 : null;

  if (String(date1).includes("T")) {
    formattedDate1 = dateTimeGetFullDate(date1);
  } else {
    formattedDate1 = new Date(date1);
  }
  if (String(date2).includes("T")) {
    formattedDate2 = dateTimeGetFullDate(date2);
  } else {
    formattedDate2 === null
      ? (formattedDate2 = new Date())
      : (formattedDate2 = new Date(date2));
  }

  var diffInMs = new Date(formattedDate1) - new Date(formattedDate2);
  var total = diffInMs / (1000 * 60 * 60 * 24);

  return total;
};

export const formatDateUSCorrectDate = (date) => {
  let newDate = new Date(date);
  newDate = `${newDate.getFullYear()}-${
    newDate.getMonth() + 1
  }-${newDate.getDate()}`;

  return newDate;
};

export const dateFnsUseDayMinus1CorrectDate = (date) => {
  let newDate = new Date(date);
  newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${
    newDate.getDate() - 1
  }`;

  return newDate;
};

export const formatDateUS = (date) => {
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  let day = newDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const dateFnsUseDayMinus1 = (date) => {
  let newDate = new Date(date);
  newDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${
    newDate.getDate() - 1
  }`;

  return newDate;
};

export const formatDate = (dateString, format = "dd/mm/yyyy") => {
  let date = endOfToday();
  if (dateString) date = parseISO(dateString);

  let monthNames = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];
  let fullMonthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  let daySearch = ("0" + day).slice(-2);
  let monthSearch = ("0" + (monthIndex + 1)).slice(-2);

  if (format === "dd") return day;
  if (format === "mm") return monthIndex + 1;
  if (format === "yyyy") return year;
  if (format === "dd MM") return day + " " + monthNames[monthIndex];
  if (format === "dd MM yyyy")
    return day + " " + monthNames[monthIndex] + " " + year;
  if (format === "dd MMMM") return day + " " + fullMonthNames[monthIndex];
  if (format === "dd/mm") return daySearch + "/" + monthSearch;
  if (format === "dd/mm/yyyy")
    return daySearch + "/" + monthSearch + "/" + year;
  if (format === "yyyy-MM") return year + "-" + monthSearch;
  if (format === "yyyy/mm/dd") return year + "/" + monthIndex + "/" + day;
  if (format === "full")
    return day + " de " + fullMonthNames[monthIndex] + " de " + year;
  if (format === "month") return monthNames[monthIndex];
};

export const formatTime = (dateString, format) => {
  let date = endOfToday();
  if (dateString) date = parseISO(dateString);

  let hour = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);

  if (format === "hh") return hour;
  if (format === "hh:MM") return hour + ":" + minutes;
  return hour + ":" + minutes + ":" + seconds;
};

export const formatDatetime = (dateString, format = "full") => {
  let date = endOfToday();
  if (dateString) date = parseISO(dateString);

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  let daySearch = ("0" + day).slice(-2);
  let monthSearch = ("0" + (monthIndex + 1)).slice(-2);

  let hour = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);

  return (
    daySearch +
    "/" +
    monthSearch +
    "/" +
    year +
    " - " +
    hour +
    ":" +
    minutes +
    ":" +
    seconds
  );
};

export const removeAccents = (string) => {
  const accents =
    "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
  const alphabetic =
    "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  let newString = "";

  for (let i = 0; i < string.length; i++) {
    let checkChange = false;
    for (let a = 0; a < accents.length; a++) {
      if (string.substr(i, 1) === accents.substr(a, 1)) {
        newString += alphabetic.substr(a, 1);
        checkChange = true;
        break;
      }
    }
    if (!checkChange) newString += string.substr(i, 1);
  }
  return newString;
};

export const formatToUrl = (string) => {
  try {
    let newString = removeAccents(string);
    newString = newString.trim().toLowerCase().replace(/ /g, "-");
    newString = newString.replace(/[^a-z0-9-]/gi, "");
    return newString;
  } catch (err) {
    return "";
  }
};

export const formatNumberLong = (number) => {
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + "K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
  return number;
};

export const yearsArray = (count = 5) => {
  const todayYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;
  const years = [nextYear, todayYear];
  for (let i = 1; i < count; i++) {
    years.push(todayYear - i);
  }
  return years;
};

export const monthsArray = [
  { id: 0, value: 1, fullValue: "01", name: "JAN", fullName: "Janeiro" },
  { id: 1, value: 2, fullValue: "02", name: "FEV", fullName: "Fevereiro" },
  { id: 2, value: 3, fullValue: "03", name: "MAR", fullName: "Março" },
  { id: 3, value: 4, fullValue: "04", name: "ABR", fullName: "Abril" },
  { id: 4, value: 5, fullValue: "05", name: "MAI", fullName: "Maio" },
  { id: 5, value: 6, fullValue: "06", name: "JUN", fullName: "Junho" },
  { id: 6, value: 7, fullValue: "07", name: "JUL", fullName: "Julho" },
  { id: 7, value: 8, fullValue: "08", name: "AGO", fullName: "Agosto" },
  { id: 8, value: 9, fullValue: "09", name: "SET", fullName: "Setembro" },
  { id: 9, value: 10, fullValue: "10", name: "OUT", fullName: "Outubro" },
  { id: 10, value: 11, fullValue: "11", name: "NOV", fullName: "Novembro" },
  { id: 11, value: 12, fullValue: "12", name: "DEZ", fullName: "Dezembro" },
];

export const cpfCnpjMask = (number) => {
  // CPF: 000.000.000-00
  // CNPJ: 00.000.000/0000-00
  const digits = number ? number.replace(/\D+/g, "") : "";

  // if (digits && digits.length <= 11) {
  if (digits.length <= 11) {
    return number
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2");
    // } else if (digits && digits.length > 11) {
  } else if (digits.length > 11) {
    return number
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  // return number;
};

export const latitudeMask = (number) => {
  // da pra melhorar
  return number.replace(/^(-)|[^0-9.,]+/g, "$1");
};
export const longitudeMask = (number) => {
  // da pra melhorar
  return number.replace(/^(-)|[^0-9.,]+/g, "$1");
};

export const phoneMask = (number) => {
  // PHONE: (00) 0000-0000
  // const digits = number ? number.replace(/\D+/g, "") : "";

  // if (digits.length > 0) {
  return number
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
  // }
  // return number;
};

export const cellphoneMask = (number) => {
  // PHONE: (00) 0000-0000
  // CELLPHONE: (00) 00000-0000
  const digits = number ? number.replace(/\D+/g, "") : "";

  // if (digits && digits.length <= 10) {
  if (digits.length <= 10) {
    return number
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2");
    // } else if (digits && digits.length > 10) {
  } else if (digits.length > 10) {
    return number
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  }
  // return number;
};

export const zipCodeMask = (number) => {
  // ZIPCODE: 00.000-000
  // const digits = number ? number.replace(/\D+/g, "") : "";

  // if (digits.length > 0) {
  return (
    number
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      // .replace(/(\d{2})(\d)/, "$1.$2")
      // .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1")
  );
  // }
  // return number;
};

export const realToNumber = (numberString) => {
  if (typeof numberString === "number") {
    return numberString.toFixed(2);
  }

  return parseFloat(numberString.replace(/\./g, "").replace(",", ".")).toFixed(
    2
  );
};

export const intToExtenso = (vlr) => extenso(vlr);
export const numberToExtenso = (vlr) => {
  let Num = parseFloat(vlr);
  if (vlr === 0) return "zero";
  else {
    let inteiro = parseInt(vlr); // parte inteira do valor
    if (inteiro < 1000000000000000) {
      let resto = Num.toFixed(2) - inteiro.toFixed(2); // parte fracionária do valor
      resto = resto.toFixed(2);
      let vlrS = inteiro.toString();

      let cont = vlrS.length;
      let extenso = "";
      let auxnumero;
      let auxnumero2;
      let auxnumero3;

      let unidade = [
        "",
        "um",
        "dois",
        "três",
        "quatro",
        "cinco",
        "seis",
        "sete",
        "oito",
        "nove",
        "dez",
        "onze",
        "doze",
        "treze",
        "quatorze",
        "quinze",
        "dezesseis",
        "dezessete",
        "dezoito",
        "dezenove",
      ];
      let centena = [
        "",
        "cento",
        "duzentos",
        "trezentos",
        "quatrocentos",
        "quinhentos",
        "seiscentos",
        "setecentos",
        "oitocentos",
        "novecentos",
      ];
      let dezena = [
        "",
        "",
        "vinte",
        "trinta",
        "quarenta",
        "cinquenta",
        "sessenta",
        "setenta",
        "oitenta",
        "noventa",
      ];
      let qualificaS = ["reais", "mil", "milhão", "bilhão", "trilhão"];
      let qualificaP = ["reais", "mil", "milhões", "bilhões", "trilhões"];

      for (let i = cont; i > 0; i--) {
        let verifica1 = "";
        let verifica2 = 0;
        let verifica3 = 0;
        auxnumero2 = "";
        auxnumero3 = "";
        auxnumero = 0;
        auxnumero2 = vlrS.substr(cont - i, 1);
        auxnumero = parseInt(auxnumero2);

        if (i === 14 || i === 11 || i === 8 || i === 5 || i === 2) {
          auxnumero2 = vlrS.substr(cont - i, 2);
          auxnumero = parseInt(auxnumero2);
        }
        if (i === 15 || i === 12 || i === 9 || i === 6 || i === 3) {
          extenso = extenso + centena[auxnumero];
          auxnumero2 = vlrS.substr(cont - i + 1, 1);
          auxnumero3 = vlrS.substr(cont - i + 2, 1);
          if (auxnumero2 !== "0" || auxnumero3 !== "0") extenso += " e ";
        } else if (auxnumero > 19) {
          auxnumero2 = vlrS.substr(cont - i, 1);
          auxnumero = parseInt(auxnumero2);
          extenso = extenso + dezena[auxnumero];
          auxnumero3 = vlrS.substr(cont - i + 1, 1);
          if (auxnumero3 !== "0" && auxnumero2 !== "1") extenso += " e ";
        } else if (
          auxnumero <= 19 &&
          auxnumero > 9 &&
          (i === 14 || i === 11 || i === 8 || i === 5 || i === 2)
        ) {
          extenso = extenso + unidade[auxnumero];
        } else if (
          auxnumero < 10 &&
          (i === 13 || i === 10 || i === 7 || i === 4 || i === 1)
        ) {
          auxnumero3 = vlrS.substr(cont - i - 1, 1);
          if (auxnumero3 !== "1" && auxnumero3 !== "")
            extenso = extenso + unidade[auxnumero];
        }
        if (i % 3 === 1) {
          verifica3 = cont - i;
          if (verifica3 === 0) verifica1 = vlrS.substr(cont - i, 1);
          if (verifica3 === 1) verifica1 = vlrS.substr(cont - i - 1, 2);
          if (verifica3 > 1) verifica1 = vlrS.substr(cont - i - 2, 3);
          verifica2 = parseInt(verifica1);
          if (i === 13) {
            if (verifica2 === 1) extenso = extenso + " " + qualificaS[4] + " ";
            else if (verifica2 !== 0)
              extenso = extenso + " " + qualificaP[4] + " ";
          }
          if (i === 10) {
            if (verifica2 === 1) extenso = extenso + " " + qualificaS[3] + " ";
            else if (verifica2 !== 0)
              extenso = extenso + " " + qualificaP[3] + " ";
          }
          if (i === 7) {
            if (verifica2 === 1) extenso = extenso + " " + qualificaS[2] + " ";
            else if (verifica2 !== 0)
              extenso = extenso + " " + qualificaP[2] + " ";
          }
          if (i === 4) {
            if (verifica2 === 1) extenso = extenso + " " + qualificaS[1] + " ";
            else if (verifica2 !== 0)
              extenso = extenso + " " + qualificaP[1] + " ";
          }
          if (i === 1) {
            if (verifica2 === 1) extenso = extenso + " " + qualificaS[0] + " ";
            else extenso = extenso + " " + qualificaP[0];
            // else extenso = extenso + " " + qualificaP[0] + " ";
          }
        }
      }

      resto = resto * 100;
      let aexCent = 0;
      if (resto <= 19 && resto > 0)
        extenso += " e " + unidade[resto] + " centavos";
      if (resto > 19) {
        aexCent = parseInt(resto / 10);
        extenso += " e " + dezena[aexCent];
        resto = resto - aexCent * 10;
        if (resto !== 0) extenso += " e " + unidade[resto] + " centavos";
        else extenso += " centavos";
      }
      return extenso;
    } else return "Número maior que 999 trilhões";
  }
};

export const normalizeArray = (array) => {
  return array.map((item) => {
    const normalizedItem = {};

    for (const key in item) {
      const nestedKeys = key.split("."); // Divide a chave em partes aninhadas
      let currentObj = normalizedItem;

      for (let i = 0; i < nestedKeys.length; i++) {
        const nestedKey = nestedKeys[i];

        if (i === nestedKeys.length - 1) {
          currentObj[nestedKey] = item[key]; // Atribui o valor à propriedade aninhada
        } else {
          currentObj[nestedKey] = currentObj[nestedKey] || {}; // Cria objeto aninhado, se não existir
          currentObj = currentObj[nestedKey]; // Atualiza a referência ao objeto atual
        }
      }
    }

    return normalizedItem;
  });
};

export const formatCreditCard = (creditCardNumber) => {
  const cardNumberArray = creditCardNumber.split("");
  const formattedNumber = [];

  for (let i = 0; i < cardNumberArray.length; i++) {
    if (i === 4 || i === 8 || i === 12) {
      formattedNumber.push(" ");
    }
    formattedNumber.push(cardNumberArray[i]);
  }

  return formattedNumber.join("");
};

export const addBusinessDayCustom = (startDate, daysToAdd) => {
  let date = new Date(startDate);
  while (daysToAdd > 0) {
    date.setDate(date.getDate() + 1); // Avança um dia
    if (!isWeekend(date)) {
      daysToAdd--; // Só decrementa se não for fim de semana
    }
  }
  return date;
};
