import React from "react";
import { Helmet } from "react-helmet";

import { ptBR } from "date-fns/locale";

import pdfStyle from "@styles/pdf";

import logo from "@assets/commercial-proposal.png";
import * as Helper from "@utils/helper";
import footer from "@assets/base-board.png";
import { format } from "date-fns";
import { StyleSheet, Font } from "@react-pdf/renderer";
import {
  Document,
  PDFViewer,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

const pageStyle = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
  },
  headerColor: {
    backgroundColor: "#595959",
    color: "#fff",
  },
  marginTop5: { marginTop: 5 },
  marginTop10: { marginTop: 10 },
  conditionTitle: { margin: 3 },
  conditionDescription: { marginBottom: 3, marginRight: 3, marginLeft: 3 },
});

const Doc = (props) => {
  const { data, categories } = props;
  const styles = { ...pdfStyle, ...pageStyle };

  const getCategory = (id) => {
    const category = categories.find((category) => category.id === id);

    return category ? category.name : "";
  };

  const clientNameFormatted = data.client_name.trim().replace(/\s+/g, "_");

  const terms = [
    {
      title: "Validade da Proposta",
      text: "A validade desta proposta é imediata a sua apresentação, podendo ser alterada sem aviso prévio conforme variação cambial, medidas governamentais e/ou disponibilidade do produto em estoque;",
    },
    {
      title: "Aceite e Preparação",
      text: "Os equipamentos locados serão disponibilizados após a formalização do aceite desta proposta comercial. Para clientes novos, além desse requisito, é necessário assinar previamente o Contrato de Locação;",
    },
    {
      title: "Assinatura de Contrato",
      text: "O Contrato de locação será gerado e enviado digitalmente para a assinatura do Responsável Legal ou pelo Procurador da empresa;",
    },
    {
      title: "Transporte e Instalação",
      text: "Estão inclusos na contratação o transporte e a entrega, a instalação, desmontagem e retirada dos equipamentos locados. No ato da entrega os equipamentos locados devem ser ligados e conferidos junto ao responsável da empresa locatária e na devolução o processo de vistoria dos equipamentos será feito por colaborador da ON.LOC. Não realizamos instalações de rede elétrica, rede lógica ou qualquer item relacionado a infraestrutura.",
    },
    {
      title: "Nota Fiscal de Devolução",
      text: "Ocasionalmente os equipamentos que acompanham Nota Fiscal de Remessa (NFe/Danfe). A empresa locatária deve providenciar a Nota Fiscal de Devolução dos equipamentos ao fim do contrato ou necessidade de troca de equipamentos em chamados técnicos. A Isenção de Inscrição Estadual ou a Não Obrigatoriedade de emissão de Notas Fiscais deverão ser comprovadas com declaração ou documento legal.",
    },
    {
      title: "SLA ONLOC",
      text: "Nossa garantia abrange equipamentos, componentes e periféricos. Oferecemos atendimento através de nosso canal de suporte dentro de um prazo de até 8 horas úteis após a solicitação, de segunda a sexta-feira, das 08h às 18h, com resolução do problema ou a substituição do(s) equipamento(s). É importante destacar que a LOCATÁRIA é responsável por indenizar a LOCADORA por defeitos não comprovados, uso inadequado ou causados por softwares instalados. Essa indenização será calculada com base nos valores dos chamados técnicos, incluindo hora técnica e despesas de visita. Para serviços fora do padrão contratados, favor consultar nossa equipe.",
    },
    {
      title: "Faturamento",
      text: "Se inicia após a entrega do(s) equipamento(s), sendo a primeira fatura calculada proporcionalmente ao tempo de uso do primeiro mês, e as faturas subsequentes são geradas com base no uso mensal. Seguem o padrão de Fatura Eletrônica com boleto bancário sem Nota Fiscal de Serviço, conforme Base Legal Federal - Lei complementar n• 116 de 31.07.2003 - Município de São Paulo - Portaria SF N• 074/03; Lei 13701 de 24.12.2003; Decreto 44540 de 29.03.2004; Consulta 2261/2004 DOM 5P 17.08. 2004.",
    },
    {
      title: "Cancelamentos de Pedidos",
      text: "O Cancelamento do pedido pode ser feito com até 03(três) dias úteis de antecedência da entrega, após este período serão faturados 20% do valor total do contrato e para cancelamentos de pedidos entregues serão faturados 30% do valor total do contrato.",
    },
    {
      title: "LGPD",
      text: "No compromisso com seus clientes a ON.LOC atua em conformidade com a Legislação vigente sobre Proteção de Dados e as determinações de órgãos reguladores/fiscalizadores sobre a matéria, em especial a Lei 13.709/2018.",
    },
    {
      title: "Momentos de Crise de Saúde Pública",
      text: "A ON.LOC, comprometida com o bem-estar de seus clientes e colaboradores, garante a sanitização de todos os equipamentos e estações de trabalho, seguindo as orientações da Anvisa, para minimizar os riscos de contaminação. O mesmo procedimento é adotado no transporte de equipamentos aos clientes.",
    },
  ];

  return (
    <Document title={data.code + "-" + clientNameFormatted}>
      {data && (
        <Page style={styles.body}>
          <View style={styles.headerImage}>
            <Image src={logo} fixed />
          </View>
          <View style={[styles.margin, styles.row, { marginTop: 85 }]}>
            <View style={[styles.flex]}>
              <Text style={[styles.text, styles.alignCenter]}>CLIENTE</Text>
              <Text
                style={[styles.subtitle, styles.alignCenter, styles.marginTop5]}
              >
                {data.client_name}
              </Text>
            </View>
            <View style={[styles.flex]}>
              <Text style={[styles.text, styles.alignRight, styles.italic]}>
                {format(
                  new Date(data.sent_date),
                  "eeee, d 'de' MMMM 'de' yyyy",
                  {
                    locale: ptBR,
                  }
                )}
              </Text>
              <View
                style={[styles.column, styles.marginTop10, styles.alignRight]}
              >
                <View>
                  <Text style={[styles.text, styles.alignRight]}>
                    Número do Orçamento
                  </Text>
                </View>
                <View style={[styles.marginPatrimony]}>
                  <Text
                    style={[
                      styles.text,
                      styles.alignRight,
                      styles.bold,
                      { fontSize: 14 },
                    ]}
                  >
                    {data.code}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {data.quotation_items?.length > 0 && (
            <View style={[styles.row]}>
              <View
                style={[styles.table, styles.borderTopLeftRight, styles.margin]}
              >
                {/* TABLE HEADER */}
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.borderBottom,
                      styles.width15,
                      styles.tableHeader,
                      styles.alignLeft,
                      styles.headerColor,
                    ]}
                  >
                    <Text style={styles.tableCell}>Categoria</Text>
                  </View>
                  <View
                    style={[
                      styles.borderBottom,
                      styles.width50,
                      styles.tableHeader,
                      styles.alignLeft,
                      styles.headerColor,
                    ]}
                  >
                    <Text style={styles.tableCell}>Descrição</Text>
                  </View>
                  <View
                    style={[
                      styles.borderBottom,
                      styles.width10,
                      styles.tableHeader,
                      styles.alignCenter,
                      styles.headerColor,
                    ]}
                  >
                    <Text style={styles.tableCell}>QTD</Text>
                  </View>
                  <View
                    style={[
                      styles.borderBottom,
                      styles.width10,
                      styles.tableHeader,
                      styles.alignCenter,
                      styles.headerColor,
                    ]}
                  >
                    <Text style={styles.tableCell}>Mês</Text>
                  </View>
                  <View
                    style={[
                      styles.borderBottom,
                      styles.width15,
                      styles.tableHeader,
                      styles.alignRight,
                      styles.headerColor,
                    ]}
                  >
                    <Text style={styles.tableCell}>Valor Total</Text>
                  </View>
                </View>
                {/* TABLE CONTENT */}
                {data.quotation_items.map((item, index) => (
                  <View
                    style={[
                      styles.tableRow,
                      index % 2 === 0 ? styles.tableStripped : null,
                    ]}
                    key={"item_" + item.id}
                  >
                    <View style={[styles.borderBottom, styles.width15]}>
                      <Text style={[styles.tableCell, styles.alignLeft]}>
                        {getCategory(item.category_id)}
                      </Text>
                    </View>
                    <View style={[styles.borderBottom, styles.width50]}>
                      <Text style={[styles.tableCell, styles.alignLeft]}>
                        {item.product_name}
                      </Text>
                    </View>
                    <View style={[styles.borderBottom, styles.width10]}>
                      <Text style={[styles.tableCell, styles.alignCenter]}>
                        {item.quantity}
                      </Text>
                    </View>
                    <View style={[styles.borderBottom, styles.width10]}>
                      <Text style={[styles.tableCell, styles.alignCenter]}>
                        {item.period}
                      </Text>
                    </View>
                    <View style={[styles.borderBottom, styles.width15]}>
                      <Text style={[styles.tableCell, styles.alignRight]}>
                        {Helper.formatNumberToReal(item.installment_value)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          )}

          <View style={[styles.row]}>
            <View
              style={[styles.table, styles.borderTopLeftRight, styles.margin]}
            >
              <View style={[styles.tableRow]}>
                <View
                  style={[
                    styles.borderBottom,
                    styles.width15,
                    styles.tableHeader,
                    styles.alignLeft,
                    styles.headerColor,
                  ]}
                >
                  <Text style={styles.tableCell}>Total Locação</Text>
                </View>
                <View
                  style={[
                    styles.borderBottom,
                    styles.width85,
                    styles.tableHeader,
                    styles.alignRight,
                  ]}
                >
                  <Text style={[styles.tableCell, styles.bold]}>
                    {Helper.formatNumberToReal(data.installment_value)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.row]}>
            <View
              style={[styles.table, styles.borderTopLeftRight, styles.margin]}
            >
              <View style={[styles.tableRow]}>
                <View
                  style={[
                    styles.borderBottom,
                    styles.width100,
                    styles.tableHeader,
                    styles.alignLeft,
                    styles.headerColor,
                  ]}
                >
                  <Text style={[styles.tableCell, styles.alignLeft]}>
                    Termos & Condições
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.table, styles.marginPatrimony]}>
              <View style={[styles.tableRow]}>
                <View style={[styles.borderAll, styles.width100]}>
                  {terms.map((term, index) => (
                    <View
                      key={"term_" + index}
                      style={[
                        styles.borderBottom,
                        index !== terms.length - 1
                          ? { borderStyle: "dotted" }
                          : {},
                      ]}
                    >
                      <Text
                        style={[
                          styles.alignLeft,
                          styles.bold,
                          styles.textLower,
                          styles.conditionTitle,
                        ]}
                      >
                        {term.title}
                      </Text>
                      <Text
                        style={[
                          styles.alignLeft,
                          styles.textLower,
                          styles.conditionDescription,
                        ]}
                      >
                        {term.text}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <Image style={styles.footerImage} src={footer} fixed />
        </Page>
      )}
    </Document>
  );
};

export default Doc;
