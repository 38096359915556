import store from "../../store";
import * as api from ".";
import * as AuthActions from "../../store/ducks/auth/actions";
import * as UtilsActions from "../../store/ducks/utils/actions";
import { uploadFromUrl } from "./file";

const REACT_APP_WEB_BASEURL = process.env.REACT_APP_WEB_BASEURL;

export async function getQuotation(query) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("quotation/list", {
        params: {
          vendorFilter: query.vendorFilter,
          search: query.search,
          rows: query.rowsPerPage,
          page: query.page,
          column: query.orderColumn,
          orientation: query.orderOrientation,
          searchSelectedId: query.searchSelectedId,
          month: query.month,
          year: query.year,
        },
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#AUG01) - Falha ao buscar orçamentos!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function getSingleQuotation(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiGet("quotation/" + id, {
        params: {},
        headers: { olsystem: token },
      });
      if (!data.error) return data;
      else return handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          "(#AUG01) - Falha ao buscar orçamento!"
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
}

export async function createQuotation(createData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost("quotation/create", createData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false, data: data };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUCU01) - Falha ao criar um orçamento ${createData.name}!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function fileUpload(id, formData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(`quotation/upload/${id}`, formData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { data: data.data, error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#ABGRS1) - Falha ao enviar arquivo de remessa!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function updateQuotation(updateData) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPut(
        "quotation/update/" + updateData.id,
        updateData,
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUUU01) - Falha ao atualizar o orçamento!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function orderQuotation(id, data) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const result = await api.apiPost("quotation/order/" + id, data, {
        headers: { olsystem: token },
      });
      if (!result.error) return { error: false };
      else handleError(result);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUUU01) - Falha ao gerar o pedido!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function sendQuotation(id, sendData) {
  const state = store.getState();
  const token = state.auth.token;

  if (token) {
    try {
      const data = await api.apiPut("quotation/send/" + id, sendData, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(sendData);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUUU01) - Falha ao atualizar o orçamento!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function openOrder(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiPost(
        "quotation/order/" + id,
        {},
        { headers: { olsystem: token } }
      );
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUUU01) - Falha ao gerar ordem de serviço!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

export async function deleteQuotation(id) {
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    try {
      const data = await api.apiDelete("quotation/delete/" + id, {
        headers: { olsystem: token },
      });
      if (!data.error) return { error: false };
      else handleError(data);
    } catch (err) {
      store.dispatch(
        UtilsActions.enqueueSnackbar(
          "error",
          `(#AUUU01) - Falha ao excluir o orçamento orcamento!`
        )
      );
    }
  } else store.dispatch(AuthActions.unauthenticated());
  return { error: true };
}

// -------------------------------------------------- LOCAL FUNCTIONS
function handleError(data) {
  if (data.status === 401) store.dispatch(AuthActions.unauthenticated());
  else if (data.status === 403) store.dispatch(AuthActions.unauthorized());
  else
    data.msg.map((m) =>
      store.dispatch(UtilsActions.enqueueSnackbar("error", m))
    );
}
