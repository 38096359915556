import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  Card,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  CardContent,
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Icon,
  InputBase,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";

import {
  RECURRENCE_PAYMENT,
  ROUTE,
  ACCOUNT_TYPEOF,
  ACCOUNT_STATUS,
} from "../../../config/constants";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";

import { colorCancel, colorSecondary } from "../../../config/colors";
import * as AccountApi from "@api/backend/account";
import * as UtilsActions from "../../../store/ducks/utils/actions";
import * as AuxActions from "../../../store/ducks/aux/actions";
import * as Helper from "../../../utils/helper";
import { useDialogAccountDetail } from "./AccountDetail";
import useStyles from "../../../styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  parse,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  format,
  isDate,
  parseISO,
  getDaysInMonth,
  endOfMonth,
  subMonths,
  subYears,
  subWeeks,
  subDays,
} from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import CancelAccount from "./CancelAccount";
import { Alert } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPartialReceive = (props) => {
  const { open, onClose, account, createAccount, closeDialog } = props;
  const [data, setData] = useState(account);

  const handleSubmit = useCallback(() => {
    onClose(data);
  }, [data]);

  const handleRecuse = useCallback(() => {
    createAccount(data);
    closeDialog();
  }, [data]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Foi recebido valor parcial ?</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
          <p>
            Identificamos que o pagamento foi menor que o valor total da conta,
            deseja gerar uma nova conta com o valor restante?
          </p>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={true}
                name="price"
                label="Valor Original"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                value={
                  data.total_history
                    ? Helper.numberToReal(data.total_history)
                    : 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                disabled={true}
                name="total"
                label="Valor recebido"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                value={data.total}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleRecuse} color="secondary">
          Recusar
        </Button>
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          variant="contained"
          color="primary"
        >
          Gerar conta adicional
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AccountCreatePayment = ({ match }) => {
  const {
    isDialogOpenPayment: show,
    closeDialog,
    accountId,
    defineAccountId,
    setForceUpate,
    dispatch,
    state,
  } = useDialogAccountDetail();

  const classes = useStyles();
  const auxProviders = useSelector((state) => state.aux.providers);
  const auxAccountTypes = useSelector((state) => state.aux.accountTypes);
  const history = useNavigate();
  const location = useLocation();

  const NEW_QUERY = {
    search: "",
    page: 1,
    rowsPerPage: 200,
    orderColumn: "id",
    orderOrientation: "asc",
  };

  const PAYMENT_ACCOUNT = {
    account_type_id: 0, //Cartão, investimento, conta corrente
    provider_id: 0,
    description: "", //Tittle
    competence: "",
    account_repeat: 1,
    due_date: new Date(),
    comments: "", //Comentário
    price: "0,00",
    category_id: 0,
    nf_number: "",
    discount: "0,00",
    tax: "0,00",
    total: "0,00",
    payed: 0,
    payment_methods: 2,
    kind_of: ACCOUNT_TYPEOF.PAYMENT,
  };

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(
    new Date()
  );
  const [paymentReceiveDate, setPaymentReceiveDate] = useState(new Date());
  const [paidStatus, setPaidStatus] = useState(false);
  const [account, setAccount] = useState(PAYMENT_ACCOUNT);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allFamilyGrouped, setAllFamilyGrouped] = useState([]);
  const [parcelOccurrence, setParcelOccurrence] = useState(1);
  const [negativeValue, setNegativeValue] = useState("black");
  const [recurrencePayment, setRecurrencePayment] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState(null);
  const [frequencyState, setFrequencyState] = useState(0);
  const [showCancelAccount, setShowCancelAccount] = useState(false);
  const [accordionInfo, setAccordionInfo] = useState(true);
  const [accordionLaunch, setAccordionLaunch] = useState(true);
  const [accordionRecurence, setAccordionRecurence] = useState(false);
  const [accordionReceive, setAccordionReceive] = useState(false);
  const [accordionComment, setAccordionComment] = useState(false);
  const [parcelTotal, setParcelTotal] = useState(0);
  const [showPartial, setShowPartial] = useState(false);

  const listClientsFormatted = useMemo(() => {
    const clientsFiltered = auxProviders;
    return clientsFiltered;
  }, [auxProviders]);

  const handleGetDomains = async () =>
    setPaymentMethods(await AccountApi.getDomains());

  const validateReceiveAccount = useCallback(() => {
    if (
      account.payed &&
      Number(Helper.realToNumber(String(account.total))) < account.total_history
    ) {
      setShowPartial(true);
    } else {
      setShowPartial(false);
      handleSubmit(account).then((result) => {
        if (result) {
          closeDialog();
          setForceUpate(true);
        }
      });
    }
  }, [account]);

  const savePartial = useCallback(
    (data) => {
      setShowPartial(false);
      handleSubmit(data).then((result) => {
        if (result) {
          const newPrice = Helper.formatNumberToReal(
            String(
              Number(data.total_history) -
                Number(Helper.realToNumber(data.total))
            ),
            "decimal"
          );

          console.log(account)
          const accountTemp = account;
          accountTemp.price = newPrice;
          accountTemp.total = newPrice;
          accountTemp.total_history = Helper.realToNumber(newPrice);
          accountTemp.receive_date = null;
          accountTemp.payed = 0;
          accountTemp.tax = 0;
          accountTemp.discount = 0;
          accountTemp.account_repeat = 1;
          accountTemp.parcels = [];
          accountTemp.comments =
            "Conta gerada com base na conta de código " +
            accountId +
            ", cópia gerada em: " +
            format(new Date(), "dd/MM/yyyy");
          accountTemp.nf_number = "";
          accountTemp.partial = true;

          setPaidStatus(false);
          defineAccountId(null)
          setAccount(accountTemp);

          const newUrl = location.pathname.split("#")[0];

          history(newUrl + "#pagamento=");
        }
      });
    },
    [closeDialog]
  );

  const handleSubmit = useCallback(
    async (account) => {
      if (account) {
        if (!account.provider_id) {
          dispatch(
            UtilsActions.enqueueSnackbar("warning", "Selecione um Fornecedor!")
          );
          return false;
        }
        if (account.category_id === 0 || account.category_id === null) {
          dispatch(
            UtilsActions.enqueueSnackbar("warning", "Selecione uma Categoria!")
          );

          return false;
        }
        if((account.account_repeat === 0 || account.account_repeat === null) && recurrencePayment){
          dispatch(
            UtilsActions.enqueueSnackbar("warning", "Número de Parcelas Inválido!")
          );

          return false;
        }
        let accountData = {
          ...account,
          price: Number(Helper.realToNumber(String(account.price))),
          tax: Number(Helper.realToNumber(String(account.tax))),
          total:
            account.total === 0
              ? 0
              : Number(Helper.realToNumber(String(account.total))),
          discount: Number(Helper.realToNumber(String(account.discount))),
          competence: account.competence
            ? account.competence
            : selectedPaymentDate,
          due_date: account.due_date
            ? account.due_date
            : `${selectedExpirationDate.getFullYear()}-${
                selectedExpirationDate.getMonth() + 1
              }-${selectedExpirationDate.getDate() + 1}`,
          payment_date:
            account.payed === 1
              ? account.payment_date
                ? account.payment_date
                : paymentReceiveDate
              : null,
          payed: account.payed,
        };
        dispatch(UtilsActions.setLoading());
        var result = null;
        if (accountId && accountId !== "null") {
          result = await AccountApi.updateAccountPayment({
            id: accountId,
            data: accountData,
          });
        } else {
          result = await AccountApi.createAccount(accountData);
        }

        dispatch(UtilsActions.resetLoading());
        if (result && result.error === false) {
          defineAccountId(result.account_id);
          return true
        } else {
          dispatch(
            UtilsActions.enqueueSnackbar("error", "Erro ao criar conta!")
          );
          return false;
        }
      }
      // eslint-disable-next-line
    },
    [
      account,
      paidStatus,
      selectedPaymentDate,
      selectedExpirationDate,
      paymentReceiveDate,
      dispatch,
      accountId,
    ]
  );

  const handleAccountCancelStatus = (status) => {
    setShowCancelAccount(status);
  };

  const handleInputDescription = useCallback(
    (event) => {
      let accountData = {
        ...account,
        description: event.target.value,
      };
      setAccount(accountData);
      // eslint-disable-next-line
    },
    [account, PAYMENT_ACCOUNT]
  );

  const handleInputComments = useCallback(
    (event) => {
      let accountData = {
        ...account,
        comments: event.target.value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handlePaymentDateChange = (date, value) => {
    const formattedDate = date;

    let paymentDate = {
      ...account,
      competence: formattedDate,
    };
    setAccount(paymentDate);
    setSelectedPaymentDate(formattedDate);
  };

  const handleExpirationDateChange = (date, value) => {
    const formattedDate = date;

    let paymentDate = {
      ...account,
      due_date: formattedDate,
    };
    setAccount(paymentDate);
    setSelectedExpirationDate(formattedDate);
  };

  const handlePaymentReceiveDate = (date, value) => {
    const formattedDate = date;

    let paymentDate = {
      ...account,
      payment_date: formattedDate,
    };
    setAccount(paymentDate);
    setPaymentReceiveDate(formattedDate);
  };

  const handleGetPaymentMethod = useCallback(
    (event) => {
      let { name, value } = event.target;
      if (name === "paymentReceiveMethod") value = event.target.value;
      let accountData = {
        ...account,
        payment_methods: value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleChangePaidStatus = useCallback(() => {
    const newStatus = account.payed === 1 ? 0 : 1;
    setAccount((prevAccount) => ({ ...prevAccount, payed: newStatus }));
    setPaidStatus(newStatus === 1);
  }, [account.payed, setAccount, setPaidStatus]);

  const handleInputAccountType = useCallback(
    (event) => {
      let { name, value } = event.target;
      if (name === "accountTypeId") value = event.target.value;
      let accountData = {
        ...account,
        account_type_id: value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleInputAccount = useCallback(
    (event) => {
      let accountTemp = { ...account };
      let { name, value } = event.target;
      if (name === "price") value = Helper.numberToReal(String(value));
      if (name === "discount") value = Helper.numberToReal(String(value));
      if (name === "tax") value = Helper.numberToReal(String(value));
      if (name === "total") value = Helper.numberToReal(String(value));
      const originalPrice =
        Number(Helper.realToNumber(account.price)) +
        Number(Helper.realToNumber(account.discount));
      accountTemp[name] = value;

      if (name !== "total") {
        if (name === "price") {
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(accountTemp.price)) -
              Number(Helper.realToNumber(accountTemp.discount)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
        if (name === "tax") {
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(accountTemp.price)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
        if (name === "discount") {
          let newValue = Helper.numberToReal(
            (
              Number(Helper.realToNumber(originalPrice)) -
              Number(Helper.realToNumber(accountTemp.discount))
            ).toFixed(2)
          );
          accountTemp["price"] = newValue;
          accountTemp["total"] = Helper.numberToReal(
            (
              Number(Helper.realToNumber(newValue)) +
              Number(Helper.realToNumber(accountTemp.tax))
            ).toFixed(2)
          );
        }
      }

      if (
        Number(Helper.realToNumber(accountTemp.price)) +
          Number(Helper.realToNumber(accountTemp.tax)) <
        Number(Helper.realToNumber(accountTemp.discount))
      ) {
        setNegativeValue(colorCancel);
      } else {
        setNegativeValue("#000");
      }
      setAccount(accountTemp);
    },
    [setAccount, account, setNegativeValue]
  );

  const handleChangeDocument = useCallback(
    (event) => {
      let accountData = {
        ...account,
        nf_number: event.target.value,
      };
      setAccount(accountData);
    },
    [account]
  );

  const handleChangeFrequency = useCallback((event) => {
    let { value } = event.target;
    value = event.target.value;
    setFrequencyState(value);
  }, []);

  const handleSetRecurrence = useCallback(
    (event) => {
      setRecurrenceType(event);
      setRecurrencePayment(!recurrencePayment);
      let tempISODate = new Date(selectedExpirationDate);
      var variableDay = Number(tempISODate.getDate());
      var variableMonth = Number(tempISODate.getMonth()) + 1;
      var variableYear = Number(tempISODate.getFullYear());
      let accountData = {
        ...account,
        account_repeat: 1,
        parcels: [
          {
            price: Number(String(account.price)),
            date: `${variableYear}-${variableMonth}-${variableDay}`,
          },
        ],
      };
      setParcelOccurrence(1);
      setAccount(accountData);
    },
    [setRecurrencePayment, recurrencePayment, account, selectedExpirationDate]
  );

  const handleParcelEdit = (index, value) => {
    const originalPrice = Number(Helper.realToNumber(account.price));
    const newPrice = Number(Helper.realToNumber(value));

    let updatedParcels = [];

    if(recurrenceType === "parcel"){
      if (newPrice > originalPrice) {
        dispatch(
          UtilsActions.enqueueSnackbar("error", "Valor maior que o Original!")
        );
        return;
      }
  
      const newParcels = account.parcels.map((parcel, i) => {
        if (i === index) {
          return {
            ...parcel,
            price: newPrice,
            edit: false,
          };
        }
        return parcel;
      });
  
      const remaingParcels = account.parcels.filter((_, i) => index !== i);
      const editableParcels = account.parcels.filter(
        (parcel, i) => index !== i && parcel.edit
      );
  
      const remaningSUM = Number(
        (
          remaingParcels.reduce((sum, parcel) => sum + parcel.price, 0) + newPrice
        ).toFixed(2)
      );
  
      const valueTodistribue = Number((originalPrice - remaningSUM).toFixed(2));
  
      const roundedIndividualValue = Number(
        (valueTodistribue / editableParcels.length).toFixed(2)
      );
  
      const totalIndividualValue = Number(
        (editableParcels.length * roundedIndividualValue).toFixed(2)
      );
  
      if (valueTodistribue !== totalIndividualValue) {
        const diff = Number((valueTodistribue - totalIndividualValue).toFixed(2));
        const lastEditParcel = editableParcels[editableParcels.length - 1];
  
        updatedParcels = newParcels.map((parcel, i) => {
          if (i === lastEditParcel?.id && i !== index) {
            return {
              ...parcel,
              price: Number(
                (parcel.price + roundedIndividualValue + diff).toFixed(2)
              ),
            };
          }
          if (i !== index && parcel?.edit && i !== newParcels.length - 1) {
            return {
              ...parcel,
              price: Number((parcel?.price + roundedIndividualValue).toFixed(2)),
            };
          }
          if (i !== index && parcel?.edit && i === newParcels.length - 1) {
            return {
              ...parcel,
              price: Number(
                (parcel?.price + roundedIndividualValue + diff).toFixed(2)
              ),
            };
          }
          return parcel;
        });
      } else {
        updatedParcels = newParcels.map((parcel, i) => {
          if (i !== index && parcel?.edit) {
            return {
              ...parcel,
              price: parcel.price + roundedIndividualValue,
            };
          }
          return parcel;
        });
      }
    }else{
      const newParcels = account.parcels.map((parcel, i) => {
        if (i === index) {
          return {
            ...parcel,
            price: newPrice,
          };
        }
        return parcel;
      });

      updatedParcels = newParcels;
    }

    setAccount({
      ...account,
      parcels: updatedParcels,
    });
  };

  const changeParcelEditLock = (index) => {
    const newParcels = account.parcels.map((parcel, i) => {
      if (i === index) {
        return {
          ...parcel,
          edit: !parcel.edit,
        };
      }
      return parcel;
    });
    setAccount({
      ...account,
      parcels: newParcels,
    });
  };

  const handleParcelDateChange = (index, value) => {
    const newParcels = account.parcels.map((parcel, i) => {
      if (i === index) {
        const newDate = new Date(value);
        return {
          ...parcel,
          date: format(newDate, "yyyy-MM-dd"),
        };
      }
      return parcel;
    });

    setAccount({
      ...account,
      parcels: newParcels,
    });
  };

  const handleClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleTextChange = (index) => (event) => {
    handleParcelEdit(index, Helper.numberToReal(event.target.value));
  };

  const formatNextMonthDate = (date) => {
    let originalDay = new Date(selectedExpirationDate).getDate();
    let nextDate = date;
    let nextDateDay = nextDate.getDate();
    if (nextDateDay !== originalDay) {
      const lastDayofMonth = getDaysInMonth(nextDate);
      if (lastDayofMonth < originalDay) {
        nextDate = endOfMonth(nextDate);
      } else {
        let variableMonth = String(nextDate.getMonth() + 1).padStart(2, "0");
        let variableYear = nextDate.getFullYear();
        let dateString = `${variableYear}-${variableMonth}-${originalDay}`;
        return parseISO(dateString);
      }
    }
    return nextDate;
  };

  const handleParcelsChange = useCallback(
    (event) => {
      let parcelNumber = recurrenceType === "repeat" ? 1 : event;
      let parcelRepeats = [...Array(event).keys()];
      let accountParcels = [];
      let parcelExample;
      let accountData;
      let tempISODate = new Date(selectedExpirationDate);
      let variableDay = String(tempISODate.getDate()).padStart(2, "0");
      let variableMonth = String(tempISODate.getMonth() + 1).padStart(2, "0");
      let variableYear = tempISODate.getFullYear();
      let dateDefine = parseISO(
        `${variableYear}-${variableMonth}-${variableDay}`
      );

      let id = 0;
      let accountPrice = Number(Helper.realToNumber(account.price));

      const parcelPrice = Number((accountPrice / parcelNumber).toFixed(2));
      const parcelPriceTotal = parcelPrice * parcelNumber;

      let lastParcelPrice;
      if (parcelPriceTotal !== accountPrice) {
        let diff = Number((accountPrice - parcelPriceTotal).toFixed(2));
        lastParcelPrice = parcelPrice + diff;
      } else lastParcelPrice = parcelPrice;

      if (frequencyState === 0)
        return dispatch(
          UtilsActions.enqueueSnackbar("warning", "Selecione uma Frequência!")
        );

      if (selectedExpirationDate !== new Date()) {
        if (frequencyState === RECURRENCE_PAYMENT.MONTHLY) {
          dateDefine = subMonths(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = formatNextMonthDate(addMonths(dateDefine, 1));
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.YEARLY) {
          dateDefine = subYears(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addYears(dateDefine, 1);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.WEEKLY) {
          dateDefine = subWeeks(dateDefine, 1);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addWeeks(dateDefine, 1);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
        if (frequencyState === RECURRENCE_PAYMENT.FORTNIGHTLY) {
          dateDefine = subDays(dateDefine, 15);
          parcelRepeats.forEach((parcel, index) => {
            const isLast = index === parcelRepeats.length - 1;
            dateDefine = addDays(dateDefine, 15);
            parcelExample = {
              id: id,
              price: isLast ? lastParcelPrice : parcelPrice,
              date: dateDefine,
              edit: true,
            };
            accountParcels.push(parcelExample);
            id++;
          });
          accountData = {
            ...account,
            account_repeat: event,
            parcels: accountParcels,
          };
        }
      }
      setAccount(accountData);
    },
    [dispatch, account, frequencyState, selectedExpirationDate]
  );

  const handleChangeOccurrences = useCallback(
    (event) => {
      let value = Number(event.target.value)
      if(!isNaN(value)){
        let occurrence = value;
        let accountData = {
          ...account,
          account_repeat: occurrence,
        };
        setParcelOccurrence(occurrence);
        setAccount(accountData);
        handleParcelsChange(Number(occurrence));
      }else{
        dispatch(
          UtilsActions.enqueueSnackbar(
            "warning",
            "Valor Inválido"
          )
        );
      }
    },
    [handleParcelsChange, account]
  );

  const handleInputCategory = useCallback(
    (event) => {
      const value = event;
      if (!event) {
        dispatch(
          UtilsActions.enqueueSnackbar(
            "warning",
            "Selecione um Plano de Contas!"
          )
        );
        setAccount((state) => ({
          ...state,
          category_id: 0,
        }));
      }

      if (value) {
        setAccount((state) => ({
          ...state,
          category_id: value,
        }));
      }
    },
    [dispatch]
  );

  const handleInputProvider = useCallback(
    (event) => {
      const value = event;
      if (!event)
        return dispatch(
          UtilsActions.enqueueSnackbar("warning", "Selecione um Provedor")
        );

      const provider = auxProviders.find((provider) => provider.id === value);

      if (provider) {
        setAccount((state) => ({
          ...state,
          provider_id: provider.id,
          name: provider.name,
        }));
      } else {
        setAccount((state) => ({
          ...state,
          client_id: account.id,
          name: account.name,
        }));
      }
    },
    [auxProviders, account, dispatch]
  );

  const handleCancel = async () => {
    history(ROUTE.FINANCIAL.ACCOUNT);
  };

  const verifyAccountDate = (date) => {
    if (date) {
      if (isDate(date)) {
        return date;
      } else return parseISO(date);
    } else return selectedPaymentDate;
  };

  const handleGroupMotherAndChild = useCallback(async () => {
    var TempGroup = await AccountApi.getCategoryList(NEW_QUERY);
    setAllFamilyGrouped(TempGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.category_id]);

  useEffect(() => {
    handleGroupMotherAndChild();
  }, [handleGroupMotherAndChild]);

  useEffect(() => {
    let sum = 0;
    if (account?.parcels) {
      sum = account?.parcels?.reduce(
        (sum, parcel) => sum + (parcel.price || 0),
        0
      );
    }
    setParcelTotal(Number(sum.toFixed(2)));
  }, [account]);

  useEffect(() => {
    async function fetchData() {
      if (accountId) {
        dispatch(UtilsActions.setLoading());
        let tempId = await AccountApi.getAccountId(accountId);
        if (tempId.data == null) {
          return history(ROUTE.FINANCIAL.ACCOUNT);
        }
        if (
          Number(tempId?.data?.account_payment?.tax) +
            Number(tempId?.data?.account_payment?.price) <
          Number(tempId?.data?.account_payment?.discount)
        ) {
          setNegativeValue(colorCancel);
        }
        setAccordionReceive(tempId.data.account_payment.payed);
        setAccordionLaunch(!tempId.data.account_payment.payed);
        setAccordionComment(tempId.data.account_payment.comments?.length > 0);
        setAccount({
          account_type_id: tempId.data.account_payment.accountTypeId,
          provider_id: tempId.data.account_payment.provider_id,
          description: tempId.data.account_payment.description,
          competence: tempId.data.account_payment.competence,
          account_repeat: tempId.data.account_payment.account_repeat,
          due_date: tempId.data.account_payment.due_date
            ? parse(
                tempId.data.account_payment.due_date,
                "yyyy-MM-dd",
                new Date()
              )
            : null,
          comments: tempId.data.account_payment.comments,
          price: Helper.numberToReal(tempId.data.account_payment.price),
          category_id: tempId.data.account_payment.category_id,
          nf_number: tempId.data.account_payment.nf_number,
          payment_date: tempId.data.account_payment.payment_date
            ? parse(
                tempId.data.account_payment.payment_date,
                "yyyy-MM-dd",
                new Date()
              )
            : null,
          closure_client_id: tempId.data.account_payment.closure_client_id,
          kind_of: account.kind_of,
          discount:
            tempId?.data?.account_payment?.discount === 0 ||
            tempId?.data?.account_payment?.discount === null
              ? "0,00"
              : Helper.numberToReal(tempId?.data?.account_payment?.discount),
          tax:
            tempId?.data?.account_payment?.tax === 0 ||
            tempId?.data?.account_payment?.tax === null
              ? "0,00"
              : Helper.numberToReal(tempId?.data?.account_payment?.tax),
              total: tempId.data.account_payment.total
              ? Helper.numberToReal(tempId.data.account_payment.total)
              : "0,00",
            total_history: tempId.data.account_payment.total
              ? tempId.data.account_payment.total
              : "0,00",
          payed: tempId.data.account_payment.payed,
          payment_methods: tempId.data.account_payment.payment_methods,
          cancel_reason: tempId.data.cancel_reason,
          cancel_observation: tempId.data.cancel_observation,
          cancel_message: tempId.data.cancel_message,
        });
        dispatch(UtilsActions.resetLoading());
      }
    }
    if (state.auth.token) {
      fetchData();
      handleGroupMotherAndChild();
      dispatch(AuxActions.getClients());
      dispatch(AuxActions.getProviders());
      dispatch(AuxActions.getAccountTypes());
      handleGetDomains();
    }

    dispatch(UtilsActions.resetLoading());
  }, [state.auth]);

  const category = allFamilyGrouped?.data?.find(
    (category) => category.id === account.category_id
  );

  return (
    <>
      <Dialog
        fullScreen
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#ccc",
          },
        }}
      >
        {showPartial && (
        <DialogPartialReceive
          open={showPartial}
          onClose={savePartial}
          closeDialog={handleClose}
          createAccount={handleSubmit}
          account={account}
        ></DialogPartialReceive>
      )}
        {showCancelAccount && (
          <CancelAccount
            open={showCancelAccount}
            accountId={accountId}
            close={handleAccountCancelStatus}
          ></CancelAccount>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.dialogtitle}>
                Conta a Pagar:{" "}
                {account.description ? account.description : "Sem título"}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={10} lg={8} sm={12}>
              <Card>
                <CardContent>
                  <Grid container item direction="column">
                  {account.partial && (
                    <Alert severity="warning" align="center">
                      Esta é uma nova conta gerada com base no recebimento
                      parcial
                    </Alert>
                  )}
                    {ACCOUNT_STATUS.some(
                      (item) =>
                        item.name === "Cancelada" && item.id == account.payed
                    ) && (
                      <Alert severity="error" align="center">
                        <strong>CONTA CANCELADA</strong> -{" "}
                        {account.cancel_message}
                      </Alert>
                    )}
                    <Accordion
                      elevation={0}
                      expanded={accordionInfo}
                      onChange={() => setAccordionInfo(!accordionInfo)}
                    >
                      <AccordionSummary>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          Informações da conta
                        </Typography>
                      </AccordionSummary>
                      <Grid container item direction="row" spacing={2}>
                        <Grid item xs={4} xs-direction="column">
                          <Autocomplete
                            name="ProviderId"
                            value={
                              listClientsFormatted?.find(
                                (provider) =>
                                  provider.id === (account.provider_id ?? 0)
                              ) || null
                            }
                            disabled={account.payed === 1}
                            options={listClientsFormatted ?? []}
                            getOptionLabel={(provider) => provider?.name}
                            noOptionsText={"Nenhum Fornecedor encontrado."}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Fornecedor"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, provider) =>
                              handleInputProvider(
                                provider !== null ? provider.id : 0
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            name="uselessName"
                            label="Descrição da conta"
                            value={account.description}
                            onChange={handleInputDescription}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KeyboardDatePicker
                            variant="outlined"
                            fullWidth
                            inputVariant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="Data vencimento"
                            disabled={account.payed === 1}
                            value={
                              account.due_date
                                ? account.due_date
                                : selectedExpirationDate
                            }
                            format="dd/MM/yyyy"
                            onChange={handleExpirationDateChange}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            required
                            name="price"
                            label="Valor Original"
                            disabled={account.payed === 1}
                            onChange={handleInputAccount}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            value={account.price}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.gridPadding}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel id="select-contract">
                              Forma de Pagamento
                            </InputLabel>
                            <Select
                              required
                              name="paymentReceiveMethod"
                              onChange={handleGetPaymentMethod}
                              disabled={account.payed === 1}
                              label="Forma de Pagamento"
                              value={account?.payment_methods}
                            >
                              {paymentMethods?.data?.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                  {type.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Accordion>
                    <Accordion
                      elevation={0}
                      expanded={accordionLaunch}
                      onChange={() => setAccordionLaunch(!accordionLaunch)}
                    >
                      <AccordionSummary>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          Lançamento da conta
                        </Typography>
                      </AccordionSummary>
                      <Grid container item direction="row" spacing={2}>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel id="select-accountType">
                              Conta
                            </InputLabel>
                            <Select
                              required
                              name="accountTypeId"
                              labelId="select-accountType"
                              label="Conta"
                              disabled={account.payed === 1}
                              onChange={handleInputAccountType}
                              value={account.account_type_id}
                            >
                              <MenuItem value="0">
                                <em>Nenhuma</em>
                              </MenuItem>
                              {auxAccountTypes.map((accountType) => (
                                <MenuItem
                                  key={accountType.id}
                                  value={accountType.id}
                                >
                                  {accountType.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <Autocomplete
                            name="category"
                            value={category || null}
                            options={allFamilyGrouped?.data?.filter(
                              (category) =>
                                category.kind_of === 1 &&
                                category.parent_id !== null
                            )}
                            getOptionLabel={(category) => category.title}
                            noOptionsText={"Nenhuma Plano de Conta encontrado."}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Plano de Contas"
                                variant="outlined"
                                defaultValue={category?.title}
                              />
                            )}
                            onChange={(event, category) =>
                              handleInputCategory(
                                category !== null ? category.id : 0
                              )
                            }
                            disabled={account.payed === 1}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KeyboardDatePicker
                            fullWidth
                            variant="filled"
                            inputVariant="outlined"
                            label="Data competência"
                            disabled={account.payed === 1}
                            value={verifyAccountDate(account.competence)}
                            format="dd/MM/yyyy"
                            onChange={handlePaymentDateChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={1}
                            name="NF/Fatura/Documento"
                            label="NF/Fatura/Documento"
                            onChange={handleChangeDocument}
                            value={account.nf_number}
                          />
                        </Grid>
                      </Grid>
                    </Accordion>
                    <Accordion
                      elevation={0}
                      expanded={accordionComment}
                      onChange={() => setAccordionComment(!accordionComment)}
                    >
                      <AccordionSummary>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          Comentário
                        </Typography>
                      </AccordionSummary>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          minRows={account.comments ? 3 : 1}
                          name="description"
                          label="Insira um Comentário..."
                          value={account.comments}
                          onChange={handleInputComments}
                        />
                      </Grid>
                    </Accordion>
                    <Accordion
                      elevation={0}
                      expanded={accordionRecurence}
                      onChange={() =>
                        setAccordionRecurence(!accordionRecurence)
                      }
                    >
                      <AccordionSummary>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                        >
                          Recorrência
                        </Typography>
                      </AccordionSummary>
                      <Grid container item direction="row" spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            <Checkbox
                              style={{ alignItems: "center" }}
                              onChange={() => handleSetRecurrence("parcel")}
                              checked={
                                recurrenceType === "parcel" &&
                                recurrencePayment === true
                              }
                              color="primary"
                              disabled={account.payed === 1}
                            />
                            Parcelar{" "}
                            <Checkbox
                              style={{ alignItems: "center" }}
                              onChange={() => handleSetRecurrence("repeat")}
                              checked={
                                recurrenceType === "repeat" &&
                                recurrencePayment === true
                              }
                              color="primary"
                              disabled={account.payed === 1}
                            />
                            Repetir{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            disabled={!recurrencePayment}
                            required={recurrencePayment}
                            variant="outlined"
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel id="select-contract">
                              Frequência
                            </InputLabel>
                            <Select
                              required
                              name="frequencySelect"
                              label="Frequência"
                              onChange={handleChangeFrequency}
                            >
                              {RECURRENCE_PAYMENT.ARRAY.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          md={5}
                          className={classes.gridPadding}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            disabled={frequencyState === 0}
                            required={
                              !recurrencePayment || frequencyState === 0
                            }
                            onChange={handleChangeOccurrences}
                            value={account.account_repeat || parcelOccurrence}
                            label="Ocorrência"
                          />
                        </Grid>
                        {recurrenceType === "parcel" && (
                          <Grid item xs={2}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              value={parcelTotal}
                              label="Valor das Parcelas"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        )}
                        <Grid container item spacing={2} xs={12}>
                          {recurrencePayment &&
                            account.parcels?.map((e, index) => (
                              <Grid
                                item
                                xs={2}
                                direction="row"
                                key={"parcels" + index}
                              >
                                <Paper
                                  style={{ backgroundColor: colorSecondary }}
                                  className={classes.paper}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item xs={9}>
                                      <Typography variant="h6">
                                        {index +
                                          1 +
                                          "/" +
                                          account.account_repeat}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Button
                                        style={{
                                          minWidth: "auto",
                                          width: "auto",
                                        }}
                                        onClick={() =>
                                          changeParcelEditLock(index)
                                        }
                                      >
                                        <Icon
                                          style={{ fontSize: "20px" }}
                                          className={
                                            e.edit
                                              ? "fas fa-unlock"
                                              : "fas fa-lock"
                                          }
                                        />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <TextField
                                    value={Helper.numberToReal(
                                      String(e.price.toFixed(2))
                                    )}
                                    onChange={handleTextChange(index)}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          R$
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <InputBase
                                    type="date"
                                    value={format(
                                      new Date(e?.date),
                                      "yyyy-MM-dd"
                                    )}
                                    onChange={(event) =>
                                      handleParcelDateChange(
                                        index,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Paper>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Accordion>
                  </Grid>
                  <Accordion
                    elevation={0}
                    expanded={accordionReceive}
                    onChange={() => setAccordionReceive(!accordionReceive)}
                  >
                    <AccordionSummary>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                      >
                        Dados de Pagamento
                      </Typography>
                    </AccordionSummary>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <Checkbox
                            style={{ alignItems: "center" }}
                            onChange={handleChangePaidStatus}
                            checked={paidStatus === true || account.payed === 1}
                            color="primary"
                          />
                          Pago{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <KeyboardDatePicker
                          fullWidth
                          variant="outlined"
                          label="Dt. Pagamento"
                          inputVariant="outlined"
                          InputLabelProps={{ shrink: true }}
                          disabled={!paidStatus && account.payed !== 1}
                          value={
                            account.payment_date
                              ? account.payment_date
                              : paymentReceiveDate
                          }
                          format="dd/MM/yyyy"
                          onChange={handlePaymentReceiveDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          // disabled={!paidStatus && account.payed !== 1}
                          name="discount"
                          label="Descontos/Taxas"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.discount}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          // disabled={!paidStatus && account.payed !== 1}
                          name="tax"
                          label="Juros/Multas"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                          value={account.tax}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          onClick={() => setPaidStatus(true)}
                          disabled={!paidStatus && account.payed !== 1}
                          name="total"
                          label="Valor pago"
                          onChange={handleInputAccount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                            style: { color: negativeValue },
                          }}
                          value={account.total}
                        />
                      </Grid>
                    </Grid>
                  </Accordion>
                </CardContent>
              </Card>
              <Grid container direction="row">
                <Grid item container xs={6} justifyContent="flex-start">
                  <DialogActions>
                    {accountId &&
                      !ACCOUNT_STATUS.some(
                        (item) =>
                          item.name === "Cancelada" && item.id == account.payed
                      ) && (
                        <Button
                          classes={{
                            root: clsx(classes.buttonRed),
                          }}
                          variant="contained"
                          onClick={() => handleAccountCancelStatus(true)}
                        >
                          Cancelar Conta
                        </Button>
                      )}
                  </DialogActions>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      color="secondary"
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => validateReceiveAccount()}
                      variant="contained"
                      color="primary"
                    >
                      {accountId ? "Atualizar" : "Confirmar"}
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Dialog>
    </>
  );
};

export default AccountCreatePayment;
