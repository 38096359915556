import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import RouteCustom from "./RouteCustom";
import { ROUTE } from "../config/constants";
import { DialogProvider } from "../views/financial/components/AccountDetail";
import QuotationFile from "../views/quotation/pages/QuotationFile";

// PUBLIC
const Login = lazy(() => import("../views/Login"));
const ClientExternal = lazy(() => import("../views/ClientExternal"));
const SuccessExternal = lazy(() => import("../views/SuccessExternal"));
// PRIVATE
const Home = lazy(() => import("../views/Home"));
const Brands = lazy(() => import("../views/Brands"));
const Providers = lazy(() => import("../views/Providers"));
const Categories = lazy(() => import("../views/Categories"));
const Calls = lazy(() => import("../views/Calls"));
const CallDetails = lazy(() => import("../views/CallDetails"));
const Audits = lazy(() => import("../views/Audit"));
const AuditDetails = lazy(() => import("../views/AuditDetails"));
const Knowledges = lazy(() => import("../views/Knowledges"));
const Clients = lazy(() => import("../views/Clients"));
const ClientDetails = lazy(() => import("../views/ClientDetails"));
const Configurations = lazy(() => import("../views/Configurations"));
const Document = lazy(() => import("../views/Document"));
const Menu = lazy(() => import("../views/Menu"));
const Models = lazy(() => import("../views/Models"));
const Products = lazy(() => import("../views/Products"));
const Proposals = lazy(() => import("../views/Proposals"));
const ProposalCreateEdit = lazy(() => import("../views/ProposalCreateEdit"));
const Purchases = lazy(() => import("../views/Purchases"));
const Reports = lazy(() => import("../views/Reports"));
const Services = lazy(() => import("../views/Services"));
const ServiceOrder = lazy(() => import("../views/ServiceOrder"));
const ServiceOrderReset = lazy(() => import("../views/ServiceOrderReset"));
const ServiceOrderSplit = lazy(() => import("../views/ServiceOrderSplit"));
const ServiceOrders = lazy(() => import("../views/ServiceOrders"));
const SubBranches = lazy(() => import("../views/subBranches"));
const Squads = lazy(() => import("../views/Squads"));
const Users = lazy(() => import("../views/Users"));
// EXPORT
const ExportProduct = lazy(() => import("../views/exports/Product"));
// PDFS
// const PdfContract = lazy(() => import('../views/pdfs/Contract'));
const pdfPatrimony = lazy(() => import("../views/pdfs/Patrimony"));
const PdfProposal = lazy(() => import("../views/pdfs/Proposal"));
const PdfAudit = lazy(() => import("../views/pdfs/TechnicalReport"));
const PdfClientReport = lazy(() => import("../views/pdfs/FinancialReport"));
// REPORTS
const ReportInventory = lazy(() => import("../views/reports/Inventory"));
const ReportReturns = lazy(() => import("../views/reports/Returns"));
const ReportRental = lazy(() => import("../views/reports/Rental"));
const ReportCalls = lazy(() => import("../views/reports/Calls"));
const ReportClientInventory = lazy(() =>
  import("../views/reports/ClientInventory")
);
const ReportProduction = lazy(() => import("../views/reports/Production"));
const ReportCity = lazy(() => import("../views/reports/City"));
const ReportExportSales = lazy(() => import("../views/reports/ExportRental"));
const ReportAddresses = lazy(() => import("../views/reports/Addresses"));
const ReportMonthly = lazy(() => import("../views/reports/Monthly"));
const ReportProductsByCity = lazy(() =>
  import("../views/reports/ProductsByCity")
);
// FINANCIAL
const FinancialRoutes = lazy(() => import("@views/financial/routes/Routes"));
const QuotationModule = lazy(() => import("@views/quotation"));
const QuotationDashboard = lazy(() =>
  import("../views/quotation/pages/QuotationDashboard")
);

// ERROR
const NotFound = lazy(() => import("../views/errors/NotFound"));

const ApplicationRoutes = () => {
  return (
    <Routes>
      {/* PUBLIC */}
      <Route
        element={<RouteCustom component={Login}></RouteCustom>}
        path={ROUTE.LOGIN}
        exact="true"
      />
      <Route
        element={<RouteCustom component={ClientExternal}></RouteCustom>}
        path={ROUTE.CLIENT_EXTERNAL + "/:token"}
      />
      <Route
        component={SuccessExternal}
        path={ROUTE.SUCCESS_EXTERNAL}
        exact="true"
      />
      {/* PRIVATE */}
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-home"
            component={Home}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.HOME}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-trademark"
            component={Brands}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.BRANDS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-address-book"
            component={Providers}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PROVIDERS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-cubes"
            component={Categories}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CATEGORIES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-comments"
            component={Calls}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CALL}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-clipboard-check"
            component={Audits}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.AUDIT}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-book-open"
            component={Knowledges}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.KNOWLEDGES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-comments"
            component={CallDetails}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CALL_DETAILS + "/:callId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-clipboard-check"
            component={AuditDetails}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.AUDIT_DETAILS + "/:auditId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-users"
            component={Clients}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CLIENTS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-users"
            component={ClientDetails}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CLIENT_DETAILS + "/:clientId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-cogs"
            component={Configurations}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CONFIGURATIONS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-th"
            component={Menu}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.MENU}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-cube"
            component={Models}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.MODELS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-laptop"
            component={Products}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PRODUCTS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-money-check-alt"
            component={Proposals}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PROPOSALS + "/:search?"}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-money-check-alt"
            component={ProposalCreateEdit}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PROPOSAL_CREATE}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-money-check-alt"
            component={ProposalCreateEdit}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PROPOSAL_EDIT + "/:proposalId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-shopping-basket"
            component={Purchases}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PURCHASES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={Reports}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORTS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-briefcase"
            component={Services}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SERVICES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-times"
            component={ServiceOrderReset}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SERVICE_ORDER_RESET}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-tools"
            component={ServiceOrderSplit}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SERVICE_ORDER_SPLIT + "/:serviceOrderId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-tools"
            component={ServiceOrder}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SERVICE_ORDERS + "/:serviceOrderId/*"}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-tools"
            component={ServiceOrders}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SERVICE_ORDERS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-code-branch"
            component={SubBranches}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SUB_BRANCHES}
        exact="true"
      />

      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-id-card"
            component={Users}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.USERS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-id-card"
            component={Squads}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.SQUADS}
        exact="true"
      />
      {/* EXPORTS */}
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-id-card"
            component={ExportProduct}
            privateRoute
          ></RouteCustom>
        }
        path={"/export"}
        exact="true"
      />
      {/* PDFS */}
      <Route
        element={
          <RouteCustom
            pdfRoute
            component={PdfProposal}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PROPOSAL_FILE + "/:proposalId/*"}
      />
      <Route
        element={
          <RouteCustom pdfRoute component={PdfAudit} privateRoute></RouteCustom>
        }
        path={ROUTE.AUDIT_FILE + "/:auditProductId/*"}
      />
      <Route
        element={
          <RouteCustom pdfRoute component={Document} privateRoute></RouteCustom>
        }
        path={ROUTE.DOCUMENT + "/:documentId/*"}
      />
      <Route
        element={
          <RouteCustom
            pdfRoute
            component={pdfPatrimony}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.PATRIMONY + "/:productId/*"}
      />
      <Route
        element={
          <RouteCustom
            pdfRoute
            component={PdfClientReport}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.CLIENT_FINANCIAL_REPORT + "/:clientId/*"}
      />
      <Route
        element={
          <RouteCustom
            pdfRoute
            component={QuotationFile}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.QUOTATION.VIEW + "/:quotationId/*"}
      />
      {/* REPORTS */}
      <Route
        element={
          <RouteCustom
            viewFaClass="fas chart-line"
            component={ReportInventory}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_INVENTORY}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas chart-line"
            component={ReportReturns}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_RETURNS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas chart-line"
            component={ReportRental}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_RENTAL}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportCalls}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_CALLS}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas chart-line"
            component={ReportClientInventory}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_CLIENT_INVENTORY}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportProduction}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_PRODUCTION}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportCity}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_CITY}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportAddresses}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_ADDRESSES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportMonthly}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_MONTHLY}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportExportSales}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_EXPORT_SALES}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-chart-line"
            component={ReportProductsByCity}
            privateRoute
          ></RouteCustom>
        }
        path={ROUTE.REPORT_PRODUCTS_CITY}
        exact="true"
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-briefcase"
            component={QuotationModule}
            privateRoute
          ></RouteCustom>
        }
        path={`${ROUTE.QUOTATION_HOME}/*`}
      />
      <Route
        element={
          <RouteCustom
            viewFaClass="fas fa-briefcase"
            component={QuotationDashboard}
            privateRoute
          ></RouteCustom>
        }
        path={`${ROUTE.QUOTATION_DASHBOARD}/*`}
      />
      <Route
        element={
          <DialogProvider>
            <RouteCustom
              viewFaClass="fas fa-dollar-sign"
              component={FinancialRoutes}
              privateRoute
            ></RouteCustom>
          </DialogProvider>
        }
        path={`${ROUTE.FINANCIAL_HOME}/*`}
      />

      {/* ERROR */}
      <Route
        element={<RouteCustom component={NotFound}></RouteCustom>}
        path="*"
      />
    </Routes>
  );
};

export default ApplicationRoutes;
